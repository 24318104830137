import { arithmeticMean } from './actions'

export const calculateWeightedHarmonic = (arrValues, arrWeights) => {
    const result = arrValues.map((value, i) => {
        const weight = arrWeights[i]
        const val = (1 / value) * weight
        return [val, weight]
    }).reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0])
    return (result[1] / result[0])
}

export const calculateWeightedMean = (arrValues, arrWeights) => {
    const result = arrValues.map((value, i) => {
        const weight = arrWeights[i]
        const sum = value * weight
        return [sum, weight]
      })
      .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0])
    return (result[0] / result[1])
}

export const getSubSectionScore = (allQuestions, group, section, subsection) => {
    const questions = removeUnansweredQuestions(allQuestions.filter(q => {
        return (q.group === group && q.section === section && q.subsection === subsection && q.omitted === false)
    }))

    const questionScores = questions.map(q => {
        return parseInt(q.score)
    })

    const aMean = arithmeticMean(questionScores)

    if (questionScores.length) return (aMean)
    return 0
}

export const removeUnansweredQuestions = (questions) => {
    return questions.filter((q, i) => {
        return (q.omitted !== true && parseInt(q.score) !== -1)
    })
}