import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from '../components/Header'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

import Landing from './Page_Main'
import ToolPage from './Page_Tool'
import AccountPage from './Page_Account'
import ContactPage from './Page_Contact'
import ReferencesPage from './Page_References'
import About from './Page_About'
import Login from './Page_Login'
import Definitions from './Page_Definitions'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-KG7PVKB'
}

TagManager.initialize(tagManagerArgs)

class App extends React.Component {
  render () {
    return (
      <Router basename={`${process.env.PUBLIC_URL}/`}>
          <Header />
          <Navigation />
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route path="/assessment">
              <ToolPage />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/definitions">
              <Definitions />
            </Route>
            <Route path="/references">
              <ReferencesPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/myaccount">
              <AccountPage />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
          </Switch>
          <Footer />
      </Router>
    )
  }
}


export default App