import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import '../styles/Page.css'
import Pdf from '../docs/tpl-guide.pdf';

class About extends React.Component {
  componentDidMount = () => {
    document.title = 'About | Technology Performance Level Assessment | NREL'
  }

  render = () => {
    return (
      <div className="Page AboutPage">
        

        <section>
          <Container className="Body">
              <Row>
                <Col md={12}>
                  
    
                    <h1>About the Technology Performance Level Assessment for Wave Energy Converters</h1>
                    <p class="lead">A technology performance level assessment provides a comprehensive measure of a wave energy converter's techno-economic performance potential. Although an assessment can be applied at any technology development stage and technology readiness level, this tool targets early-stage concepts.
                   </p>
                    <p>This tool is specialized for wave energy farms that service utility-scale, grid-connected power markets. The tool uses a series of questions in seven categories to guide the assessor in the evaluation of a wave energy converter. Each question addresses an aspect that impacts the techno-economic performance of the converter. For each question, the reviewer must provide a score. Because the tool is intended to evaluate the techno-economic performance of a range of wave energy converter archetypes—which may be impacted differently by different evaluation criteria—the assessor also has the option to turn off inapplicable criteria.
                    </p>
                    
                    <p>The Technology Performance Level Assessment methodology was jointly developed by NREL and Sandia National Laboratories. Funding was provided by the U.S. Department of Energy Office of Energy Efficiency and Renewable Energy Water Power Technologies Office.</p>
                
               
                    <h2>Required Information</h2>
                    <p>For a detailed inventory of the information needed to conduct an assessment, see the <a href={Pdf}>TPL Assessment Guide</a>. </p>
                

                 
                    <h2>Best Practices</h2>
                    <p>When performing an assessment, follow these best practices: </p>
                    <ul>
                    <li><p>For each question, provide a comment that describes how the score was determined. Reference any document used to collect background information. When there is insufficient background information, assign a high score uncertainty.   </p>
                  </li>
                    <li><p>Two or more assessors should independently assess a technology and then compare scores, discuss differences, and arrive at consensus. An assessment will have the most value after independent assessors resolve disparities in scores.  </p>
                    </li>
                    <li><p>Developers who want their technologies assessed by laboratories must first do an assessment themselves and provide the background information and assessment to the laboratories. </p>
                   </li>
                    <li><p>When possible, scoring should be based on rigorously cited, developer-provided information. If the necessary information is not provided—causing the assessor to extrapolate, perform calculations, or make assumptions—document the reasoning used, along with any additional references, as scoring justification.</p>
                   </li>
                   <li><p>Assessors should consult the <a href="https://tpl.nrel.gov/references">references</a> and the Reference Model examples for useful context and as a point of comparison for scoring. </p></li>
                    <li><p>Not all questions can be answered with high confidence and rigorous reasoning. Each score should therefore be assigned a confidence level (high, medium, or low). Assign a high confidence level to scores based on clearly documented and reasonable developer-provided information or calculated therefrom in a straightforward manner. If a score is based on reasonable extensions, minor assumptions, or slight extrapolations from developer-provided data and scoring guidance, assign a medium confidence level and document the extensions, assumptions, or extrapolations. Assign a low confidence level to scores based on gratuitous assumption from little information or for which the information is dubious or has a high degree of uncertainty.</p></li>
    </ul>
                  

                  
                    <h2>Assessment Examples</h2>
                    <p>Use these assessment examples as references for your work. </p>

                    <p><a href="/rm3.json" download>Reference Model 3: Point Absorber</a></p>
                    
                    <p><a href="/rm5.json" download>Reference Model 5: Surge Flap</a></p>

                    <p><a href="/rm6.json" download>Reference Model 6: Oscillating Water Column</a></p>

                    <p>To view an assessment example:</p>

                    <ol>
                      <li>Download the reference model JSON file using the link above.</li>
                      <li>Click the Upload link in the navigation bar at the top of this website.</li>
                      <li>In the pop-up, select the reference model file, and click Open.</li>
                    </ol>

                    <p>The assessment tool will populate with data from the reference model file.</p>
               
                    <h2>Disclaimer</h2>
                    <p>Alliance for Sustainable Energy LLC is the Manager and Operator of the National Renewable Energy Laboratory (“NREL”) under Prime Contract No. DE-AC36-08GO28308 for the U.S. Department of Energy (“DOE”). National Technology and Engineering Solutions of Sandia LLC (“NTESS”) is the Manager and Operator of Sandia National Laboratories (“SNL”) under Prime Contract No. DE-NA0003525 for the DOE. Alliance and NTESS developed the Technology Performance Level Evaluation (“TPL”), an assessment tool for analyzing a wave energy converter design (“WEC Design”) based on technical information, modeling, test results, and/or data (“Data”) provided by the WEC developer (“WEC Developer”). TPL analyzes the Data to generate a holistic assessment of the WEC Design under technical, economic, social, environmental, ecological, and safety considerations (“Analysis”).</p>

                    <p>ALLIANCE AND NTESS ARE PROVIDING THE ANALYSIS “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR ACCURACY. WEC DEVELOPER’S USE OF OR RELIANCE ON THE ANALYSIS IS AT ITS SOLE RISK. IN PARTICULAR, BUT WITHOUT LIMITING THIS DISCLAIMER, ALLIANCE AND NTESS DO NOT REPRESENT OR WARRANT THAT: (A) WEC DEVELOPER’S USE OF THE ANALYSIS WILL MEET WEC DEVELOPER’S REQUIREMENTS; (B) THE ANALYSIS IS FREE FROM ERROR; OR (C) ANY INFORMATION OBTAINED BY THE WEC DEVELOPER AS A RESULT OF ITS USE OF THE ANALYSIS WILL BE ACCURATE OR RELIABLE.</p>

                </Col>
              </Row>
          </Container>
        </section>
      </div>
    )
  }
}

export default About
