import React from 'react'
import { faFacebook, faInstagram, faLinkedin, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function () {
  return (
        <footer id="footer" class="hidden-print">
        
        <div class="footertop">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <a href="https://www.nrel.gov/index.html"><strong>National Renewable Energy Laboratory</strong></a>
                    </div>
                    <div class="col col-lg-7">
                        <div class="d-flex flex-column flex-lg-row justify-content-lg-start global">
                            <div><a href="https://www.nrel.gov/about/index.html">About</a></div>
                            <div><a href="https://www.nrel.gov/research/index.html">Research</a></div>
                            <div><a href="https://www.nrel.gov/workingwithus/index.html">Work with Us</a></div>
                            <div><a href="https://www.nrel.gov/news/index.html">News</a></div>
                            <div><a href="https://www.nrel.gov/careers/index.html">Careers</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footerbottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-lg-5">
                        <div><a  id="contact-link" href="/contact">Contact Us</a></div>
                        <div class="mt-2"><a href="https://www.nrel.gov/about/visiting-nrel.html">Visit</a></div>
                        <div class="mt-2"><a href="https://www.nrel.gov/news/subscribe.html">Subscribe</a></div>
                        <div class="mt-3">
                            <ul class="social-links list-inline">
                                <li class="list-inline-item"><a href="https://www.facebook.com/nationalrenewableenergylab" aria-label="Follow NREL on Facebook"><FontAwesomeIcon icon={faFacebook} /></a>
                                </li>
                                <li class="list-inline-item"><a href="https://www.instagram.com/nationalrenewableenergylab/" aria-label="Follow NREL on Instagram"><FontAwesomeIcon icon={faInstagram} /></a></li>
                                <li class="list-inline-item"><a href="https://www.linkedin.com/company/national-renewable-energy-laboratory"
                                    aria-label="Follow NREL on Linked In"><FontAwesomeIcon icon={faLinkedin} /></a>
                                </li>
                                <li class="list-inline-item"><a href="https://www.youtube.com/user/NRELPR/" aria-label="Follow NREL on YouTube"><FontAwesomeIcon icon={faYoutube} /></a>
                                </li>
                                <li class="list-inline-item"><a href="https://twitter.com/nrel/" aria-label="Follow NREL on Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-8 col-lg-7 globalsecondary">
                        <div class="row">
                            <div class="col-sm-6 col-lg-3">
                                <div class="mt-1"><a href="https://www.nrel.gov/accessibility.html">Accessibility</a></div>
                                <div class="mt-1"><a href="https://www.nrel.gov/disclaimer.html">Disclaimer</a></div>
                                <div class="mt-1"><a href="https://www.nrel.gov/security.html">Security and Privacy</a></div>
                                <div class="mt-1"><a href="https://tpl.nrel.gov/contact">Site Feedback</a></div>
                            </div>
                            <div class="col-sm-6 col-lg-3">
                                
                                <div class="mt-1"><a href="https://developer.nrel.gov/">Developers</a></div>
                                <div class="mt-1"><a href="https://thesource.nrel.gov/">Employees</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="mt-4">
                <div class="row">
                    <div class="col-lg-5">
                        <a href="https://www.allianceforsustainableenergy.org/"><img class="mb-3" src="https://www.nrel.gov/assets/images/alliance-logo_black.png" alt="Alliance for Sustainable Energy, LLC" /></a>
                        <a href="https://energy.gov"><img class="ml-5" src="https://www.nrel.gov/_resources/images/logo-doe-footer.png" alt="U.S. Department of Energy" /></a>
                    </div>
                    <div class="col-12 col-lg-7 mt-3 mt-lg-0">
                        <p class="nrel-attr">The National Renewable Energy Laboratory is a national laboratory of the <a href="https://energy.gov/">U.S. Department of Energy</a>, <a href="https://energy.gov/eere/office-energy-efficiency-renewable-energy">Office of Energy Efficiency and Renewable Energy</a>, operated by the <a href="https://www.allianceforsustainableenergy.org/">Alliance for Sustainable Energy LLC</a>.</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
    </footer>
  )
}