import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import OverallScore from '../components/OverallScore'
import GroupScores from '../components/GroupScores'
import Assessment from './Assessment.js'

import { removeUnansweredQuestions } from '../helpers'
import { showQuestion, openSubSection, openSection, openSubSubSection, saveAssessment, loadAssessment, selectGroup } from '../actions'

const exactMath = require('exact-math')

class ToolPage extends React.Component {
    componentDidMount = () => {
        window.addEventListener('click', this.handleClick)
    }

    componentWillUnmount = () => {
        window.removeEventListener('click', this.handleClick)
    }

    calculateProgress = () => {
        const answeredQuestions = removeUnansweredQuestions(this.props.questions)
        const answeredCount = answeredQuestions.length
        const count = this.props.questions.filter((q) => { return (q.omitted === false )}).length
        const progress = exactMath.round(((answeredCount / count) * 100), -1)
        return progress
    }

    handleClick = async (event) => {
        if (event.target.classList.contains('QuestionLink')) {
            const question_id = event.target.dataset.question_id
            const question = this.props.questions[question_id]
            this.props.selectGroup(`${question.group}`); // This will take a bit to re-render the dom
            if (question.subsubsection !== null) {
                await this.props.openSubSubSection(question.subsubsection)
                this.scrollToElement('Question-' + question_id)
            }
        
            if (question.subsubsection === null && question.subsection !== null) { 
                await this.props.openSubSection(question.subsection)
                this.scrollToElement('Question-' + question_id)
            }
        
            if ((question.subsubsection === null && question.subsection === null) && question.section !== null){ 
                await this.props.openSection(question.section)
                this.scrollToElement('Question-' + question_id)
            }
        }
    }

    handleSave = async () => {
        this.props.saveAssessment()
    }

    handleLoad = async () => {
        this.props.loadAssessment()
    }

    scrollToElement = (el_id) => {
        const el = document.getElementById(el_id)
        el.scrollIntoView({
            block: 'center'
        })
        setTimeout(() => {
            el.animate([
                {transform: 'scale(1)'},
                {transform: 'scale(1.045)'},
                {transform: 'scale(1)'}
            ], 450)
        }, 500);
    }

    render () {
        const progress = this.calculateProgress()
        return (
            <Container fluid="xxl">
                <div className="scoresHeader sticky-top">
                    <Row>
                        <Col md="4"><OverallScore progress={progress} groups={this.props.groups}/></Col>
                        <Col md="8"><GroupScores label="Group" charts={this.props.groups}/></Col>
                    </Row> 
                </div>
                <Assessment />
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loaded: state.loaded,
        groups: state.groups,
        questions: state.questions
    }
}

const mapDispatchToProps = {
    showQuestion,
    openSubSection,
    openSection,
    openSubSubSection,
    saveAssessment,
    loadAssessment,
    selectGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolPage)