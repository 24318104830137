import React from 'react'
import { connect } from 'react-redux'
import Group from '../components/Group'
import { setScores } from '../actions'


class Assessment extends React.Component {
    componentDidMount = () => {
        document.title = 'Assessment Tool | Technology Performance Level Assessment | NREL'
        this.props.setScores()
    }

    getQuestions = (groupId) => {
        return this.props.questions.filter((question) => {
            return (question.group === groupId)
        })
    }

    getTopLevelSections = (groupId) => {
        return this.props.sections.filter((section) => {
            return ((section.group === groupId))
        })
    }

    render () {
        const GROUPS = this.props.groups.map((group, i) => {
            const isOpen = group.id === parseInt(this.props.activeGroupId)
            const groupQuestions = this.getQuestions(group.id)
            const topLevelSections = this.getTopLevelSections(group.id)
            return <Group key={`Group-${i}`} group={group} questions={groupQuestions} sections={topLevelSections} isOpen={isOpen} /> 
        })
        return (
            <div>
                {GROUPS}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        groups: state.groups,
        sections: state.sections,
        subsections: state.subsections,
        subsubsections: state.subsubsections,
        questions: state.questions,
        activeGroupId: state.activeGroupId
    }
}

const mapDispatchToProps = {
    setScores,
}

export default connect(mapStateToProps, mapDispatchToProps)(Assessment)