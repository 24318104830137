import { SET_SCORES, SET_QUESTION_SCORE, OMIT_QUESTION, RESTORE_QUESTION, OPEN_SECTION, OPEN_SUBSECTION, OPEN_SUBSUBSECTION, CLOSE_SECTION, CLOSE_SUBSECTION, CLOSE_SUBSUBSECTION, SAVE_UNCERTAINTY, SAVE_COMMENT, LOAD_FROM_FILE, SET_GROUP} from './_action-types'
import produce from 'immer'
import store from '../store'

const exactMath = require('exact-math')

export const setScores = () => async (dispatch) => {
    let SECTIONS = store.getState().sections
    let GROUPS = store.getState().groups
    let SUBSECTIONS = store.getState().subsections
    let SUBSUBSECTIONS = store.getState().subsubsections

    const CAPEX = calculateCapexSection()
    const OPEX = calculateOpexSection()
    const PERFORMANCE = calculatePerformanceSection()
    const AVAILABILITY = calculateSection(3)
    const CAPEX_UNCERTAINTY = calculateSubSection(9)
    const OPEX_UNCERTAINTY = calculateSubSection(10)
    const PERFORMANCE_UNCERTAINTY = calculateSubSection(11)
    const AVAILABILITY_UNCERTAINTY = calculateSubSection(12)
    const IMPACT = calculateSection(6)
    const GREENHOUSE = calculateSection(7)
    const ENVIRONMENTAL_IMPACT = calculateSection(8)
    const ECOLOGICAL_IMPACT = calculateSection(9)
    const AREA_OF_USE_CONFLICTS = calculateSection(10)
    const EXTREME_LOADS = calculateSubSection(13)
    const GRID_FAILURE = calculateSubSection(14)
    const COLLISIONS = calculateSubSection(15)
    const TEMP_CONDITIONS = calculateSubSection(16)
    const FATIGUE = calculateSubSection(17)
    const CONFIGURATION = calculateSubSection(18)
    const SAFETY = calculateSection(11)
    const SURVIVABLE = calculateSurvivableSection(EXTREME_LOADS, GRID_FAILURE, COLLISIONS, TEMP_CONDITIONS, FATIGUE, CONFIGURATION)
    const DEPLOYMENT = calculateSection(13)

    const NEW_GROUPS = produce(GROUPS, draftState => {
        draftState[0].score = exactMath.round(calculateCostOfEnergy(CAPEX, OPEX, PERFORMANCE, AVAILABILITY), -1)
        draftState[1].score = exactMath.round(calculateInvestmentOpportunity(CAPEX_UNCERTAINTY, OPEX_UNCERTAINTY, PERFORMANCE_UNCERTAINTY, AVAILABILITY_UNCERTAINTY), -1)
        draftState[2].score = exactMath.round(calculateSection(5), -1)
        draftState[3].score = exactMath.round(calculateBenefitToSociety(IMPACT, GREENHOUSE), -1)
        draftState[4].score = exactMath.round(calculatePermitting(ENVIRONMENTAL_IMPACT, ECOLOGICAL_IMPACT, AREA_OF_USE_CONFLICTS), -1)
        draftState[5].score = exactMath.round(calculateSafety(SAFETY, SURVIVABLE), -1)
        draftState[6].score = exactMath.round(DEPLOYMENT, -1)
    })

    const NEW_SECTIONS = produce(SECTIONS, draftState => {
        draftState[0].score  = exactMath.round(CAPEX, -1)
        draftState[1].score  = exactMath.round(OPEX, -1)
        draftState[2].score  = exactMath.round(PERFORMANCE, -1)
        draftState[3].score  = exactMath.round(AVAILABILITY, -1)
        draftState[4].score  = exactMath.round(calculateInvestmentOpportunity(CAPEX_UNCERTAINTY, OPEX_UNCERTAINTY, PERFORMANCE_UNCERTAINTY, AVAILABILITY_UNCERTAINTY), -1)
        draftState[5].score  = exactMath.round(calculateSection(5), -1)
        draftState[6].score  = exactMath.round(IMPACT, -1)
        draftState[7].score  = exactMath.round(GREENHOUSE, -1)
        draftState[8].score  = exactMath.round(ENVIRONMENTAL_IMPACT, -1)
        draftState[9].score  = exactMath.round(ECOLOGICAL_IMPACT, -1)
        draftState[10].score = exactMath.round(AREA_OF_USE_CONFLICTS, -1)
        draftState[11].score = exactMath.round(SAFETY, -1)
        draftState[12].score = exactMath.round(SURVIVABLE, -1)
        draftState[13].score = exactMath.round(DEPLOYMENT, -1)
    })

    const NEW_SUBSECTIONS = produce(SUBSECTIONS, draftState => {
        draftState.map((sub, i) => {
            return draftState[i].score = exactMath.round(calculateSubSection(sub.id), -1)
        })
    })

    const NEW_SUBSUBSECTIONS = produce(SUBSUBSECTIONS, draftState => {
        draftState.map((subsub, i) => {
            return draftState[i].score = exactMath.round(calculateSubSubSection(subsub.id), -1)
        })
    })

    dispatch({
        type: SET_SCORES,
        payload: {
            groups:  NEW_GROUPS,
            sections: NEW_SECTIONS,
            subsections: NEW_SUBSECTIONS,
            subsubsections: NEW_SUBSUBSECTIONS
        }
    })
}

export const saveAssessment = () => async (dispatch) => {
    const state = store.getState()
    let element = document.createElement('a')
    element.style.display = 'none'
    element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(state)))
    const promptMsg = 'Please provide a filename for this TPL assessment.  It will be provided a file extension of ".json".'
    let fileName = prompt(promptMsg,'tpl-assessment')
    if (fileName !== null)  {
        fileName = fileName.replace(/</g, "&lt;").replace(/>/g, "&gt;")
        element.setAttribute('download', `${fileName}.json`)
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }
}

export const loadAssessment = () => async (dispatch) => {
    let element = document.createElement('input')
    element.type = "file"
    document.body.appendChild(element)
    element.onchange = () => {
        const fr = new FileReader();
        fr.onload = (e) => {
            const result = JSON.parse(e.target.result)
            dispatch({
                type: LOAD_FROM_FILE,
                payload: result
            })
            // Always ove user to first question group after file upload
            dispatch({
                type: SET_GROUP,
                payload: {activeGroupId: '0'}
            })
        }
        fr.readAsText(element.files.item(0))
        document.body.removeChild(element)
    }
    element.click()
}

const UpdateQuestionFieldFactory = (type) => (question_id, value) => async (dispatch) => {
    const QUESTIONS = store.getState().questions
    const questionsToUpdate = QUESTIONS.filter((q, _ ) => {
        return (q.invalidated_by === question_id || q.id === question_id)
    })

    const updatedQuestions = produce(questionsToUpdate, draftState => {
        draftState.map((_, i) => {
            return (draftState[i][type] = value)
        })
    })

    const mergedArray = [...updatedQuestions, ...QUESTIONS]
    let set = new Set()
    let unionArray = mergedArray.filter(item => {
        if (!set.has(item.id)) {
            set.add(item.id)
            return true
        }
        return false
    }, set).sort((a,b) => {
        return a.id - b.id
    })

    dispatch({
        type: SET_QUESTION_SCORE,
        payload: { questions: unionArray }
    }) 
}

export const setQuestionScore = UpdateQuestionFieldFactory("score")
export const setQuestionUncertainty = UpdateQuestionFieldFactory("uncertainty")

export const saveComment = (question_id, comment_text) => async (dispatch) => {
    const QUESTIONS = store.getState().questions
    const insertionIndex = QUESTIONS.findIndex(x => x.id === question_id)
    const NEW_QUESTIONS = insertIntoArray(QUESTIONS, insertionIndex, {...QUESTIONS[insertionIndex], comment: comment_text})
    dispatch({
        type: SAVE_COMMENT,
        payload: {questions: NEW_QUESTIONS}
    })
}

export const selectGroup = (group_id) => async (dispatch) => {
    dispatch({
        type: SET_GROUP,
        payload: {activeGroupId: group_id}
    })
}

export const openSection = (section_id) => async (dispatch) => {
    const SECTIONS = store.getState().sections
    const NEW_SECTIONS = insertIntoArray(SECTIONS, section_id, {...SECTIONS[section_id], open: true})
    dispatch({
        type: OPEN_SECTION,
        payload: {sections: NEW_SECTIONS}
    })
}

export const closeSection = (section_id) => async (dispatch) => {
    const SECTIONS = store.getState().sections
    const NEW_SECTIONS = insertIntoArray(SECTIONS, section_id, {...SECTIONS[section_id], open: false})
    dispatch({
        type: CLOSE_SECTION,
        payload: {sections: NEW_SECTIONS}
    })
}

export const openSubSection = (subsection_id) => async (dispatch) => {
    const SECTIONS = store.getState().sections
    const SUBSECTIONS = store.getState().subsections
    const section_id = SUBSECTIONS[subsection_id].section
    const NEW_SECTIONS = insertIntoArray(SECTIONS, section_id, {...SECTIONS[section_id], open: true})
    const NEW_SUBSECTIONS = insertIntoArray(SUBSECTIONS, subsection_id, {...SUBSECTIONS[subsection_id], open: true})
    dispatch({
        type: OPEN_SUBSECTION,
        payload: {
            sections: NEW_SECTIONS,
            subsections: NEW_SUBSECTIONS
        }
    })
}

export const closeSubSection = (subsection_id) => async (dispatch) => {
    const SUBSECTIONS = store.getState().subsections
    const NEW_SUBSECTIONS = insertIntoArray(SUBSECTIONS, subsection_id, {...SUBSECTIONS[subsection_id], open: false})
    dispatch({
        type: CLOSE_SUBSECTION,
        payload: {subsections: NEW_SUBSECTIONS}
    })
}

export const openSubSubSection = (subsubsection_id) => async (dispatch) => {
    const SECTIONS = store.getState().sections
    const SUBSECTIONS = store.getState().subsections
    const SUBSUBSECTIONS = store.getState().subsubsections
    const section_id = SUBSUBSECTIONS[subsubsection_id].section
    const subsection_id = SUBSUBSECTIONS[subsubsection_id].subsection
    const NEW_SECTIONS = insertIntoArray(SECTIONS, section_id, {...SECTIONS[section_id], open: true})
    const NEW_SUBSECTIONS = insertIntoArray(SUBSECTIONS, subsection_id, {...SUBSECTIONS[subsection_id], open: true})
    const NEW_SUBSUBSECTIONS = insertIntoArray(SUBSUBSECTIONS, subsubsection_id, {...SUBSUBSECTIONS[subsubsection_id], open: true})
    dispatch({
        type: OPEN_SUBSUBSECTION,
        payload: {
            sections: NEW_SECTIONS,
            subsections: NEW_SUBSECTIONS,
            subsubsections: NEW_SUBSUBSECTIONS
        }
    })
}

export const closeSubSubSection = (subsubsection_id) => async (dispatch) => {
    const SUBSUBSECTIONS = store.getState().subsubsections
    const NEW_SUBSUBSECTIONS = insertIntoArray(SUBSUBSECTIONS, subsubsection_id, {...SUBSUBSECTIONS[subsubsection_id], open: false})
    dispatch({
        type: CLOSE_SUBSUBSECTION,
        payload: {subsubsections: NEW_SUBSUBSECTIONS}
    })    
}


export const omitQuestion = (question_id) => async (dispatch) => {
    const QUESTIONS = store.getState().questions
    const updated_questions = insertIntoArray(QUESTIONS, question_id, {...QUESTIONS[question_id], omitted: true})
    dispatch({
        type: OMIT_QUESTION,
        payload: {questions: updated_questions}
    })
}

export const restoreQuestion = (question_id) => async (dispatch) => {
    const QUESTIONS = store.getState().questions
    const updated_questions = insertIntoArray(QUESTIONS, question_id, {...QUESTIONS[question_id], omitted: false})
    dispatch({
        type: RESTORE_QUESTION,
        payload: {questions: updated_questions}
    })
}

export const showQuestion = (question_id) => async (dispatch) => {
    const QUESTIONS = store.getState().questions
    const question = QUESTIONS[question_id]
    if (question.subsubsection !== null) {
        openSubSubSection(question.subsubsection) 
    }

    if (question.subsubsection === null && question.subsection !== null) { 
        openSubSection(question.subsection) 
    }

    if ((question.subsubsection === null && question.subsection === null) && question.section !== null){ 
        openSection(question.section) 
    }
}

const insertIntoArray = (originalArray, insertionIndex, newData) => {
    return produce(originalArray, draftState => {
        draftState[insertionIndex] = newData
    })
}

const calculateCostOfEnergy = (CAPEX_SCORE, OPEX_SCORE, PERFORMANCE_SCORE, AVAILABILITY_SCORE) => {
    const hMeanCapexOpex = calculateWeightedHarmonic([CAPEX_SCORE, OPEX_SCORE], [0.7, 0.3]) 
    const gMean = geometricMean([hMeanCapexOpex, PERFORMANCE_SCORE, AVAILABILITY_SCORE])
    return gMean
}

const calculateBenefitToSociety = (IMPACT_SCORE, GREENHOUSE_SCORE) => {
    return calculateWeightedMean([IMPACT_SCORE, GREENHOUSE_SCORE], [0.5, 0.5])
}

const calculateInvestmentOpportunity = (CAPEX_UNCERTAINTY, OPEX_UNCERTAINTY, PERFORMANCE_UNCERTAINTY, AVAILABILITY_UNCERTAINTY) => {
    const aMeanCapexOpex = calculateWeightedMean([CAPEX_UNCERTAINTY, OPEX_UNCERTAINTY], [0.7, 0.3])
    const gMean = geometricMean([aMeanCapexOpex, PERFORMANCE_UNCERTAINTY, AVAILABILITY_UNCERTAINTY])
    return gMean
}

const calculatePermitting = (ENVIRONMENTAL_IMPACT_SCORE, ECOLOGICAL_IMPACT_SCORE, AREA_OF_USE_CONFLICTS_SCORE) => {
    return geometricMean([ENVIRONMENTAL_IMPACT_SCORE, ECOLOGICAL_IMPACT_SCORE, AREA_OF_USE_CONFLICTS_SCORE])
}

const calculateSafety = (SAFETY_SCORE, SURVIVABLE_SCORE) => {
    return geometricMean([SAFETY_SCORE, SURVIVABLE_SCORE])
}

const calculateCapexSection = () => {
    let DESIGN = calculateSubSection(0)
    let MANUFACTURABILITY = calculateSubSection(1)
    let TRANSPORTABILITY = calculateSubSection(2)
    let INSTALLIBILITY = calculateSubSection(3)
    const capexScore = calculateWeightedMean([DESIGN, MANUFACTURABILITY, TRANSPORTABILITY, INSTALLIBILITY], [0.364, 0.364, 0.091, 0.182])
    return exactMath.round(capexScore, -1)
}

const calculateOpexSection = () => {
    let RELIABILITY = calculateSubSection(4)
    let MAINTAINABILITY = calculateSubSection(5)
    const opexScore = calculateWeightedMean([RELIABILITY, MAINTAINABILITY], [0.7, 0.3])
    return exactMath.round(opexScore, -1)
}

const calculatePerformanceSection = () => {
    let ENERGY_CAPTURE = calculateSubSection(6)
    let ENERGY_CONVERSION = calculateSubSection(7) 
    return exactMath.round(geometricMean([ENERGY_CAPTURE, ENERGY_CONVERSION]), -1)
}

const calculateSurvivableSection = (a,b,c,d,e,f) => {
    return exactMath.round(geometricMean([a,b,c,d,e,f]), -1)
}

const calculateSection = (sectionId) => {
    const sectionScores = store.getState().questions.filter((q) => {
        return (q.omitted !== true && q.section === sectionId)
    }).map((q)=> {
        if (parseInt(q.score) === -1) {
            return 1
        }
        return parseInt(q.score)
    })
    if (sectionScores.length) return arithmeticMean(sectionScores)
    return 1
}

const calculateSubSection = (subsectionId) => {
    const subsectionScores = store.getState().questions.filter((q) => {
        return (q.omitted !== true && q.subsection === subsectionId)
    }).map((q) => {
        if (parseInt(q.score) === -1) {
            return 1
        }
        return parseInt(q.score)
    })
    if (subsectionScores.length) return arithmeticMean(subsectionScores)
    return 1
}

const calculateSubSubSection = (subsubsectionId) => {
    const subsubsectionScores = store.getState().questions.filter((q) => {
        return (q.omitted !== true && q.subsubsection === subsubsectionId)
    }).map((q) => {
        if (parseInt(q.score) === -1) {
            return 1
        }
        return parseInt(q.score)
    })
    if (subsubsectionScores.length) return arithmeticMean(subsubsectionScores)
    return 1
}

export const geometricMean = (arrValues) => {
    let product = 1.0;
    for (let i = 0; i < arrValues.length; i++)  {
        product = product * arrValues[i];
    }
    let exponent = (1 / arrValues.length);
    let result = Math.pow(product, exponent);
    
    return result;
}

export const arithmeticMean = (arrValues) => {
    var sum = 0.0;
    for (let i = 0; i < arrValues.length; i++) {
        sum += arrValues[i];
    }
    return (sum / arrValues.length);
}

const calculateWeightedHarmonic = (arrValues, arrWeights) => {
    const result = arrValues.map((value, i) => {
        const weight = arrWeights[i]
        const val = (1 / value) * weight
        return [val, weight]
    }).reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0])
    return (result[1] / result[0])
}

const calculateWeightedMean = (arrValues, arrWeights) => {
    const result = arrValues.map((value, i) => {
        const weight = arrWeights[i]
        const sum = value * weight
        return [sum, weight]
      })
      .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0])
    return (result[0] / result[1])
}