import React, {Component} from 'react'
import { Row, Col, Progress} from 'reactstrap'
import SectionCharts from './ScoreCharts'
import Section from './Section'
import { removeUnansweredQuestions } from '../helpers'
import '../styles/Group.css'
const exactMath = require('exact-math')

class Group extends Component {

    renderSectionScoreCharts = () => {
        const sectionHtml = this.props.sections.map((section, i) => {
            return (
                <div key={`SectionChart-${this.props.group.id}-${i}`}>
                    {section.title}
                </div>
            )
        })
        return sectionHtml
    }

    renderSections = () => {
        return this.props.sections.map((section, i) => {
            const sectionIsOpen = (this.props.isOpen && i === 0)
            return (<Section key={`Section-Key-${this.props.group.id}-${i}`} section={section} isOpen={sectionIsOpen} />)
        })
    }

    calculateGroupProgress = () => {
        const answeredQuestions = removeUnansweredQuestions(this.props.questions)
        const answeredCount = answeredQuestions.length
        let count = this.props.questions.filter((q) => { return (q.omitted === false )}).length
        if (count === 0)    {
            return 100 // the entire Group is omitted so it is 100% done
        }
        const progress = exactMath.round(((answeredCount / count) * 100), -1)
        return progress
    }

    render = () => {
        const progress = this.calculateGroupProgress()
        const sections = this.renderSections()
        const groupClassName = "Group__Header TRL3-" + this.props.group.id
        if (this.props.isOpen)  {
            return (
                <section>
                    <div className={groupClassName} key={`GroupKey-${this.props.group.id}`} id={`Group-${this.props.group.id}`} >
                        <Row>
                            <Col md={6}>
                                <Col md={4}>
                                    <div className="Group__Title" key={`Toggle-${this.props.group.id}`} onClick={this.toggleGroup}>{this.props.group.title}</div>
                                </Col>
                                <Col md={8}>
                                    Progress
                                    <Progress value={progress}></Progress>
                                </Col>
                                <Col className="Group__Description" md={12}>
                                    {this.props.group.description}
                                </Col>
                            </Col>
                        <Col md={6}>
                            <SectionCharts charts={this.props.sections} label='Section' />
                        </Col>
                    </Row>
                </div>

                {sections}
                
            </section>
        )
    } else  {
        return null
    }
    }
}

export default Group