import React, {Component} from 'react'
import { Row, Col, Progress } from "reactstrap"
import { geometricMean } from '../actions'
import '../styles/OverallScore.css'

const exactMath = require('exact-math')

class OverallScore extends Component {
    calculateOverallScore = (groups) => {
        const COE_OP_BDG = geometricMean([groups[0].score, groups[1].score, groups[6].score]) * 0.7
        const GO_BTS = geometricMean([groups[2].score, groups[3].score]) * 0.1
        const PC_SF = geometricMean([groups[4].score, groups[5].score]) * 0.2
        return exactMath.round((COE_OP_BDG + GO_BTS + PC_SF), -1)
    }
    
    render = () => {
        const overallScore = this.calculateOverallScore(this.props.groups)
        return (
            <div className="OverallScore">
                <div className="OverallScore__Score">
                    Current TPL Score: {overallScore}
                </div>

                <section className="OverallScore__Legend">
                    <h4>TPL Scoring</h4>
                    <span>Low: 1-3</span>
                    <span>Medium: 4-6</span>
                    <span>High: 7-9</span>
                </section>

                <div className="OverallScore__Progress">
                    <Row>
                        <Col className="OverallScore__Progress--Title" md="4">
                            Progress
                        </Col> 
                        <Col md="8">
                            <Progress animated className="OverallProgress" value={this.props.progress}/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default OverallScore

