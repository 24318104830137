import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Row, Col, Collapse} from 'reactstrap'
import {openSubSubSection, closeSubSubSection} from '../actions'
import Question from './Question'
import '../styles/SubSubSection.css'

class SubSubSection extends Component {

    toggleSubSubSection = () => {
        if (this.props.subsubsection.open === false) this.props.openSubSubSection(this.props.subsubsection.id)
        if (this.props.subsubsection.open === true) this.props.closeSubSubSection(this.props.subsubsection.id)
    }

    render = () => {
        const questions = this.props.questions.filter ((q, i) => {
            return q.subsubsection === this.props.subsubsection.id
        }).map((q, id) => {
            return (
                <Question key={`Question-Key-${id}`} question={q} score={q.score} guidanceOpen={false} scoringGuidanceOpen={false} omitted={q.omitted} comment={q.comment} uncertainty={q.uncertainty}/>
            )        
        })
        const caretIcon = (this.props.subsubsection.open) ? <svg aria-hidden="true" focusable="false" className="toggleCaret" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path></svg> : <svg className="toggleCaret" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
        return (
            <section key={`SubSubSection-${this.props.subsubsection.id}`} id={`SubSubSection-${this.props.subsubsection.id}`} className="SubSubSection">
                <Row>
                    <Col md={12}>
                        <div className="SubSubSection__Title" onClick={this.toggleSubSubSection}>
                            {this.props.subsubsection.title} {caretIcon}
                        </div>
                        <div className="SubSubSection__Description">
                            {this.props.subsubsection.description}
                        </div>
                        <Collapse isOpen={this.props.subsubsection.open}>
                            {questions}
                        </Collapse>
                    </Col>
                </Row>
            </section>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        questions: state.questions,
        subsections: state.subsections,
        subsubsections: state.subsubsections,
    }
}

export default connect(mapStateToProps, {openSubSubSection, closeSubSubSection})(SubSubSection)
