import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Row, Col, Collapse } from 'reactstrap'
import SubSubSection from './SubSubSection'
import Question from './Question'
import '../styles/SubSection.css'
import {openSubSection, closeSubSection} from '../actions'

class SubSection extends Component {


    toggleSubSection = () => {
        if (this.props.subsection.open === false) this.props.openSubSection(this.props.subsection.id)
        if (this.props.subsection.open === true) this.props.closeSubSection(this.props.subsection.id)
    }

    getSubSubSections = () => {
        return this.props.subsubsections.filter((subsub, i) => {
            return (subsub.subsection === this.props.subsection.id)
        })
    }

    getSubSectionQuestions = () => {
        return this.props.questions.filter((q, i) => {
            return (q.subsection === this.props.subsection.id)
        })
    }

    renderQuestions = () => {
        return this.props.questions.filter((question, i) => {
            return (question.subsection === this.props.subsection.id)
        }).map((q, id) => {
            return (
                <Question key={`Question-Key-${id}`} question={q} score={q.score} guidanceOpen={false} scoringGuidanceOpen={false} omitted={q.omitted} comment={q.comment} uncertainty={q.uncertainty}/>
            )
        })
    }

    renderSubSubSections = () => {
        return this.props.subsubsections.filter((sub, i) => {
            return (sub.subsection === this.props.subsection.id)
        }).map((subsub, id) => {
            return (
                <SubSubSection key={`SubSubSection-Key-${id}`} subsubsection={subsub} />
            )
        })
    }


    render = () => {
        const subsubsections = this.getSubSubSections()
        let subSubSections = null
        let colSize = 12
        let questions = null
        if (subsubsections.length) {
            subSubSections = this.renderSubSubSections()
            colSize = 6
        } else {
            questions = this.renderQuestions()
        }
        const subSectionClassName = "SubSection TRL1-" + this.props.activeGroupId
        const caretIcon = (this.props.subsection.open) ? <svg aria-hidden="true" focusable="false" className="toggleCaret" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path></svg> : <svg className="toggleCaret" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>

        return (
            <section key={`SubSection-${this.props.subsection.id}`} id={`SubSection-${this.props.subsection.id}`} className={subSectionClassName}>
                <Row>
                    <Col md={colSize}>
                        <div onClick={this.toggleSubSection} className="SubSection__Title">{this.props.subsection.title} {caretIcon}</div>
                        <div>{this.props.subsection.description}</div>
                    </Col>

                    <Collapse isOpen={this.props.subsection.open} className="flex-grow-1">
                        {questions}
                        <div className="SubSubSections">{subSubSections}</div>
                    </Collapse>
                </Row>
            </section>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        questions: state.questions,
        subsections: state.subsections,
        subsubsections: state.subsubsections,
        activeGroupId: state.activeGroupId,
    }
}

export default connect(mapStateToProps, {openSubSection, closeSubSection})(SubSection)
