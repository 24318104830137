import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Row, Col, Collapse} from 'reactstrap'
import SubSectionCharts from './ScoreCharts'
import SubSection from './SubSection'
import Question from './Question'
import '../styles/Section.css'
import {openSection, closeSection} from '../actions'

class Section extends Component {

    toggleSection = () => {
        if (this.props.section.open === false) this.props.openSection(this.props.section.id)
        if (this.props.section.open === true) this.props.closeSection(this.props.section.id)
    }

    getSubSections = () => {
        return this.props.subsections.filter((subsection, i) => {
            return (subsection.section === this.props.section.id)
        })
    }

    renderSubSections = () => {
        const subSections = this.getSubSections()
        return subSections.map((subsection, i) => {
            return <SubSection key={`SubSectionKey-${subsection.id}`} subsection={subsection} />
        })
    }

    renderQuestions = () => {
        return this.props.questions.filter((question, i) => {
            return (question.section === this.props.section.id)
        }).map((q, id) => {
            return (
                <Question key={`Question-Key-${id}`} question={q} score={q.score} guidanceOpen={false} scoringGuidanceOpen={false} omitted={q.omitted} comment={q.comment} uncertainty={q.uncertainty}/>
            )
        })
    }

    render = () => {
        const subSectionCharts = this.getSubSections()
        const subSections = this.renderSubSections()
        let questions = null
        const sectionClassName = "Section TRL2-" + this.props.activeGroupId
        if (subSections.length === 0) questions = <div className="Questions">{this.renderQuestions()}</div>

        const caretIcon = (this.props.section.open) ? <svg aria-hidden="true" focusable="false" className="toggleCaret" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path></svg> : <svg className="toggleCaret" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>

        return (
            <section key={`Section-${this.props.section.id}`} id={`Section-${this.props.section.id}`} className={sectionClassName}>
                <Row>
                    <Col md={6}>
                        <div className="Section__Header" key={`SectionKey-${this.props.section.id}`} id={`Section-${this.props.section.id}`} >
                            <div onClick={this.toggleSection} className="Section__Title">{this.props.section.title} {caretIcon}</div>
                        </div>
                        <div className="Section__Description">
                            {this.props.section.description}
                        </div>
                    </Col>
                    <Col md={5}>
                        <SubSectionCharts charts={subSectionCharts} label='SubSection'/>
                    </Col>
                </Row>

                <Collapse isOpen={this.props.section.open}>
                    {subSections}
                    {questions}
                </Collapse>
            </section>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        questions: state.questions,
        sections: state.sections,
        subsections: state.subsections,
        subsubsections: state.subsubsections,
        activeGroupId: state.activeGroupId
    }
}

export default connect(mapStateToProps, {openSection, closeSection})(Section)
