const groupsBase = [
    {
        "id": 0,
        "title": "Cost of Energy",
        "description": null,
        "color": "#00B5EF"
    },

    {
        "id": 1,
        "title": "Investment Opportunity",
        "description": "It is critical that WEC farm risks are well understood and manageable. Financial risk is determined by analysis of the probabilities of the risks and their financial consequences. Uncertainties about costs (CapEx and OpEx), revenues (energy production and availability), and survivability are the drivers.",
        "color": "#0079C2"
    },

    {
        "id": 2,
        "title": "Grid Operation",
        "description": null,
        "color": "#0C5C8D"
    },

    {
        "id": 3,
        "title": "Societal Benefit",
        "description": "Local benefits may include job creation, increase in economic activity, protection from coastal erosion, increase in the tax base, and improvement of infrastructure. Local benefits shall largely overcome possible negative impacts (e.g., visual obstruction in the seascape).",
        "color": "#707070"
    },
    
    { 
        "id": 4,
        "title": "Permitting and Certification",
        "description": null,
        "color": "#CE372F"
    },

    {
        "id": 5,
        "title": "Safety and Function",
        "description": "The WEC power farm shall be safe at each stage of its life cycle.",
        "color": "#F7901E"
    },

    {
        "id": 6,
        "title": "Global Deployment",
        "description": null,
        "color": "#FDCE13"
    }
]

export const GROUPS = groupsBase.map(group => {
    group.score = 0;
    return group
});

export const SECTIONS = [
    {
        "id": 0,
        "group": 0, 
        "title": "CapEx",
        "description": "Capital expenditure (CapEx) includes all costs that occur in the construction, installation, and decommissioning of a WEC. The drivers of CapEx are design, manufacturability, transportability, and installability. CapEx includes costs related to grid connection.",
        "score": 0,
        "open": true
    },
    {
        "id": 1,
        "group": 0,
        "title": "OpEx",
        "description": null,
        "score": 0,
        "open": false
    },
    {
        "id": 2,
        "group": 0,
        "title": "Performance",
        "description": null,
        "score": 0,
        "open": false       
    },
    {
        "id": 3,
        "group": 0,
        "title": "Availability",
        "description": null,
        "score": 0,
        "open": false
    },
    {
        "id": 4,
        "group": 1,
        "title": "Investment Opportunity",
        "description": "It is critical that WEC farm risks are well understood and manageable. Financial risk is determined by analysis of the probabilities of the risks and their financial consequences. Uncertainties about costs (CapEx and OpEx), revenues (energy production and availability), and survivability are the drivers.",
        "score": 0,
        "open": false
    },
    {
        "id": 5,
        "group": 2,
        "title": "Forecastable",
        "description": "The electricity market requires prediction of energy production to enable optimal dispatch and to manage power system operations, such as maintaining equilibrium with generation and load; thus, energy production from the WEC farm should be forecastable. Typical prediction horizons are in the range of 20 minutes to season-ahead.",
        "score": 0,
        "open": false
    },
    {
        "id": 6,
        "group": 3,
        "title": "Impact on Local Communities",
        "description": "Local benefits may include job creation, increase in economic activity, protection from coastal erosion, increase in the tax base, and improvement of infrastructure. Local benefits shall largely overcome possible negative impacts (e.g., visual obstruction in the seascape).",
        "score": 0,
        "open": false
    },
    {
        "id": 7,
        "group": 3,
        "title": "Greenhouse Gas Emission and Pollution",
        "description": "The WEC farm must be a low-greenhouse-gas-emission energy source over its entire life cycle. The WEC farm should not pollute the environment during construction, operation, or disposal.  ",
        "score": 0,
        "open": false
    },
    {
        "id": 8,
        "group": 4,
        "title": "Environmental Impacts",
        "description": "The WEC technology and design shall enable the construction of a project that meets all environmental regulations.",
        "score": 0,
        "open": false
    },
    {
        "id": 9,
        "group": 4,
        "title": "Ecological Impacts",
        "description": "The WEC technology and design shall enable the construction of a project that minimizes wildlife impacts.",
        "score": 0,
        "open": false
    },
    {
        "id": 10,
        "group": 4,
        "title": "Area of Use Conflicts",
        "description": null,
        "score": 0,
        "open": false
    },
    {
        "id": 11,
        "group": 5,
        "title": "Safety",
        "description": "The WEC power farm shall be safe at each stage of its life cycle.",
        "score": 0,
        "open": false
    },    
    {
        "id": 12,
        "group": 5,
        "title": "Survivable",
        "description": "Major loss events may happen. The financial consequences (loss of revenues because of downtime, loss of assets, cost of repairs) shall be well understood and as low as possible.",
        "score": 0,
        "open": false
    },
    {
        "id": 13,
        "group": 6,
        "title": "Deployment",
        "description": null,
        "score": 0,
        "open": false
    }

]

export const SUBSECTIONS = [
    {
        "id": 0,
        "group": 0, 
        "section": 0,
        "title": "Design",
        "description": "The WEC should have a design that minimizes CapEx costs. This design should minimize loads, complexity, and material costs yet still be able to function in all expected operating conditions.",
        "score": 0,
        "open": false
    },
    {
        "id": 1,
        "group": 0,
        "section": 0,
        "title": "Manufacturability",
        "description": "The WEC farm should be highly reliable to avoid costly unplanned maintenance. Repair costs for unplanned maintenance should be low. Costs include replacement parts, transportation to and from the site of repair, fees incurred as a result of wait times for weather windows, and fees for trained workers.",
        "score": 0,
        "open": false
    },
    {
        "id": 2,
        "group": 0,
        "section": 0,
        "title": "Transportability (Excluding Install)",
        "description": "WEC components should be built close to the manufacturing or deployment site to minimize shipping and transportation costs. Alternatively, the components and subsystems should be of a size and modularity that makes standard transportation possible.",
        "score": 0,
        "open": false
    },
    {
        "id": 3,
        "group": 0,
        "section": 0,
        "title": "Installability",
        "description": "The WEC (including all components) should be installable in a range of weather conditions, require minimal time to install, use readily available vessels, and minimize the need for skilled workers.",
        "score": 0,
        "open": false
    },
    {
        "id": 4,
        "group": 0,
        "section": 1,
        "title": "Reliability (Cost of Unplanned Maintenance) and Durability",
        "description": "The WEC farm should be highly reliable to avoid costly unplanned maintenance. Repair costs for unplanned maintenance should be low. Costs include replacement parts, transportation to and from the site of repair, fees incurred as a result of wait times for weather windows, and fees for trained workers. The WEC should be highly durable to minimize planned maintenance. The number of components subject to wear, abrasion, and erosion should be minimal. Ideally, the durability of a WEC's components is the same as the lifetime of the farm. Planned maintenance costs should be low. Costs could include replacement parts, transportation to and from the site, fees incurred as a result of wait times for weather windows, and fees for trained workers. Costs do not include lost revenue as a result of downtime.",
        "score": 0,
        "open": false

    },
    {
        "id": 5,
        "group": 0,
        "section": 1,
        "title": "Maintainability",
        "description": "The WEC should be designed for easy repair and replacement of parts and, if it is a floating device, for easy release for tow-in for major maintenance.",
        "score": 0,
        "open": false
    },
    {
        "id": 6,
        "group": 0,
        "section": 2,
        "title": "Energy Capture",
        "description": "The WEC should absorb a high percentage of wave energy. This implies that the WEC can absorb energy across a range of frequencies, heights, and wave directions. It should be minimally affected by tide, current, and wind. Availability is not covered here.",
        "score": 0,
        "open": false
    },
    {
        "id": 7,
        "group": 0,
        "section": 2,
        "title": "Energy Conversion",
        "description": "The WEC should have a small number of conversion steps, and each step should be highly efficient. Availability is not covered here.",
        "score": 0,
        "open": false
    },
    {
        "id": 8,
        "group": 0,
        "section": 3,
        "title": "Availability",
        "description": "Availability is the ratio of time that the WEC is available to produce power (including time when the wave resource is not sufficient to generate power) to total time.",
        "score": 0,
        "open": false
    },

    {
        "id": 9,
        "group": 1,
        "section": 4,
        "title": "CapEx Uncertainty",
        "description": "CapEx may be greater than expected because of an increase in materials or component prices or increased manufacturing, transportation, or installation costs.",
        "score": 0,
        "open": false
    },

    {
        "id": 10,
        "group": 1,
        "section": 4,
        "title": "OpEx Uncertainty",
        "description": "OpEx may be uncertain due to a lack of data on the reliability or durability of WEC components.",
        "score": 0,
        "open": false
    },

    {
        "id": 11,
        "group": 1,
        "section": 4,
        "title": "Performance Uncertainty",
        "description": "Uncertainty about resource characterization, WEC performance, and WEC availability reduces investor confidence in project revenue.",
        "score": 0,
        "open": false
    },

    {
        "id": 12,
        "group": 1,
        "section": 4,
        "title": "Availability Uncertainty",
        "description": "Availability may be less than expected because of uncertainties in the reliability or the durability of components. Availability may also be diminished because waiting time between weather windows for planned and unplanned maintenance is longer than expected.",
        "score": 0,
        "open": false
    },
    {
        "id": 13,
        "group": 5,
        "section": 12,
        "title": "Extreme Loads",
        "description": "The WEC should be able to survive extreme loads/responses caused by extreme weather conditions or high-power operational conditions.",
        "score": 0,
        "open": false
    },
    {
        "id": 14,
        "group": 5,
        "section": 12,
        "title": "Grid Failure",
        "description": "The WEC should be able to cope with grid failure, grid loss, or grid interruption (black start capability) as well as a grid loss coupled with communication loss.",
        "score": 0,
        "open": false
    },
    {
        "id": 15,
        "group": 5,
        "section": 12,
        "title": "Collisions",
        "description": "The WEC should be able to avoid and survive collisions with ships, other marine users, and mammals.",
        "score": 0,
        "open": false
    },
    {
        "id": 16,
        "group": 5,
        "section": 12,
        "title": "Temporary Conditions",
        "description": "The WEC should be survivable in temporary conditions, including installation (and tow-out, if applicable) and maintenance.",
        "score": 0,
        "open": false
    },
    {
        "id": 17,
        "group": 5,
        "section": 12,
        "title": "Fatigue",
        "description": "The WEC should have robust fatigue characteristics",
        "score": 0,
        "open": false
    },
    {
        "id": 18,
        "group": 5,
        "section": 12,
        "title": "Configuration",
        "description": "The WEC should have robust configuration changes.",
        "score": 0,
        "open": false
    }
]

export const SUBSUBSECTIONS = [
    {
        "id": 0,
        "group": 0,
        "section": 0,
        "subsection": 0,
        "title": "General Question",
        "description": null,
        "score": 0,
        "open": false
    },
    {
        "id": 1,
        "group": 0,
        "section": 0,
        "subsection": 0,
        "title": "Wave Energy Converter",
        "description": "These questions apply to the primary body of the WEC in all expected operating and safety configurations. Exclude station-keeping systems if they are distinct embodiments (e.g., mooring lines) and not part of the primary body.",
        "score": 0,
        "open": false
    },
    {
        "id": 2,
        "group": 0,
        "section": 0,
        "subsection":0,
        "title": "Power Take-Off",
        "description": "Answer the following questions for the power take-off (PTO), the system that converts mechanical power (or equivalent) absorbed by the WEC to usable/transportable power (such as electricity or pressurized fluid). This includes elements such as drivetrains, generators, and pumps.",
        "score": 0,
        "open": false
    },
    {
        "id": 3,
        "group": 0,
        "section": 0,
        "subsection":0,
        "title": "Mooring",
        "description": null,
        "score": 0,
        "open": false
    },
    {
        "id": 4,
        "group": 0,
        "section": 0,
        "subsection":0,
        "title": "Other Capex Questions",
        "description": null,
        "score": 0,
        "open": false
    },
    {
        "id": 5,
        "group": 0,
        "section": 7,
        "subsection":7,
        "title": "For the WEC",
        "description": null,
        "score": 0,
        "open": false
    },
    {
        "id": 6,
        "group": 0,
        "section": 7,
        "subsection":7,
        "title": "For the Power Collection System (After the WEC Output to the Point of Connection)",
        "description": null,
        "score": 0,
        "open": false
    }

]