import React, {Component} from 'react'
import { connect } from 'react-redux'
import { selectGroup } from '../actions'

class GroupScores extends Component {
    scrollIntoView = (event) => {
        const closestLink = event.target.closest('.ScoreChart__link')
        const linkid = closestLink.dataset.linkid

        this.props.selectGroup(linkid)
    }

    render = () => {
        const groupCharts = this.props.charts.map(chart => {
            const height = chart.score ? (chart.score * 7) + (chart.score * 2) : 10
            const heightStyle = {height: height + 'px', backgroundColor: chart.color}
            const href = `${this.props.label}-${chart.id}`
            let linkClassName = "ScoreChart__link"
            const curGroup = this.props.groups.find(group => group.id === chart.id)
            if (curGroup.id === parseInt(this.props.activeGroupId))   {
                linkClassName += " TRL2-" + curGroup.id
            }
            return (
                <li key={`scoreChart-${this.props.label}-${chart.id}`}>
                    <div className={linkClassName} data-scrollto={href} data-linkid={chart.id} onClick={this.scrollIntoView}>
                        <div className="ScoreChart__score">
                            <span>{chart.score}</span>
                        </div>
                        <div style={heightStyle} className="ScoreChart__bar"></div>
                        <p className="ScoreChart__title">{chart.title}</p>
                    </div>
                </li>                
            )
        })

        return (
            // .ScoreCharts + .ScoreCharts__Charts are necessary for the proper styling of the Group Score chart
            <div className="ScoreCharts">
                <ul className="ScoreCharts__Charts">
                    {groupCharts}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        groups: state.groups,
        activeGroupId: state.activeGroupId
    }
}

export default connect(mapStateToProps, { selectGroup })(GroupScores)
