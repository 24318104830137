import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import '../styles/Page.css'

class References extends React.Component {
  componentDidMount = () => {
    document.title = 'References | Technology Performance Level Assessment | NREL'
  }

  render = () => {
    return (
      <div className="Page">


    <section>
      <Container className="Body">
          <Row>
            <Col md={12}>
         <h1>References</h1>
              <p class="lead">These references were used in the development of the Technology Performance Level Assessment tool.</p>
              <section className="References">

                  <h2>Reference Models</h2>
                  <ul><li>
                    <p><a href="https://energy.sandia.gov/wp-content/gallery/uploads/SAND2014-9040-RMP-REPORT.pdf"><em>Methodology for Design and Economic Analysis of Marine Energy Conversion (MEC) Technologies</em></a>, Sandia Report (2014) </p></li>
                    <li><p><a href="https://www.nrel.gov/docs/fy15osti/64013.pdf"><em>Levelized Cost of Energy Analysis of Marine and Hydrokinetic Reference Models</em></a>, Third Annual Marine Energy Technology Symposium (2015)</p></li>
                    <li><p><a href="https://www.icoe-conference.com/documents/aDBwSGQ5KzUxa0p5MzhnYmZnRTJIbGRsaUYvQUI1S2U3eFBhZ0MwMG4vQ3ZTZ1Z6WjVoK2xDSi9PL2VpYkNTNkxEc2w0V25zdWYySU5YemFkSzdZUmc9PXwwMjJiN2ExMmMzN2RlZDI0ZjlkZjdhMzg4NGUzMzkyYQ/">Long Term Load Response of a Two-Body Point Absorber in Extreme Seas</a>, International Conference on Ocean Energy Presentation (2018)</p>
                  </li>
                  </ul>



                  <h2>Structural Design</h2>
                  <ul><li>
                    <p><a href="https://rules.dnvgl.com/docs/pdf/dnvgl/OS/2015-07/DNVGL-OS-C101.pdf"><em>Design of Offshore Steel Structures, General - LRFD Method</em></a>, DNV GL Offshore Standard (2015) </p></li>
                      <li>
                    <p><a href="https://www.dnvgl.com/oilgas/download/dnvgl-rp-c203-fatigue-design-of-offshore-steel-structures.html"><em>Fatigue Design of Offshore Steel Structures</em></a>, DNV GL Recommended Practice (2014) </p>
                  </li>
                  </ul>



                  <h2>Embodied Energy</h2>
                  <ul>
                    <li><p><a href="https://journals.sagepub.com/doi/10.1243/09576509JPE483">Energy and Carbon Audit of an Offshore Wave Energy Converter</a>, <em>Proceedings of the Institution of Mechanical Engineers, Part A: Journal of Power and Energy</em> (2007)</p></li>
                    <li><p><a href="https://www.icevirtuallibrary.com/doi/10.1680/ener.2008.161.2.87">Embodied Energy and Carbon in Construction Materials</a>, <em>Proceedings of the Institution of Civil Engineers &mdash; Energy</em> (2008)</p></li>
                    <li><p><a href="https://era.ed.ac.uk/handle/1842/8875"><em>Carbon and Energy Payback of Variable Renewable Generation</em></a>, University of Edinburgh Thesis (2014)</p></li>
                  </ul>



                  <h2>Recyclability</h2> 
                  <ul>
                    <li><p><a href="https://www.osha.gov/Publications/OSHA3348-metal-scrap-recycling.pdf"><em>Guidance for the Identification and Control of Safety and Health Hazards in Metal Scrap Recycling</em></a>, OSHA Report (2008)</p> </li>
                    <li><p><a href="https://greet.es.anl.gov/files/glass-fiber-update"><em>Life-Cycle Analysis Update of Glass and Glass Fiber for the GREET Model</em></a>, Argonne National Laboratory Report (2015)</p></li>
                  </ul>



                  <h2>Sea State Definition</h2>
                  <ul>
                    <li><p><a href="https://www.weather.gov/pqr/beaufort">Beaufort Scale</a>, National Weather Service</p>  </li>  
                    <li><p><a href="https://en.wikipedia.org/wiki/Beaufort_scale">Beaufort Scale</a>, Wikipedia</p></li>
                  </ul>




                  <h2>Offshore Installation</h2> 
                  <ul>
                    <li>
                      <p><a href="https://www.sciencedirect.com/science/article/pii/S0029801817304547">Offshore Wind Installation Vessels – A Comparative Assessment for UK Offshore Rounds 1 and 2</a>, <em>Ocean Engineering</em> (2018)  </p>
                    </li>
                  </ul>



                  <h2>Beneficial to Society</h2>
                  <ul>
                    <li>
                      <p><a href="https://www.nrel.gov/docs/fy12osti/55781.pdf"><em>Jobs and Economic Development Impact (JEDI) Model Geothermal User Reference Guide</em></a>, NREL Technical Report (2012)</p>
                    </li>
                    <li><p><a href="https://www.nrel.gov/analysis/jedi/marine-hydro.html">JEDI Marine and Hydrokinetic Power Model</a>, NREL </p>
                    </li>
                  </ul>
                  <h2>Permitting and Certification</h2>
                  <ul>
                    <li><p><a href="https://doi.org/10.1109/OCEANS.2010.5664064">Assessing the Effects of Marine and Hydrokinetic Energy Development on Marine and Estuarine Resources</a>, Oceans 2010 MTS/IEEE Seattle (2010) </p></li>
                  <li><p><a href="https://www.nrel.gov/docs/fy16osti/66688.pdf"><em>A Review of the Environmental Impacts for Marine and Hydrokinetic Projects To Inform Regulatory Permitting: Summary Findings from the 2015 Workshop on Marine and Hydrokinetic Technologies, Washington, D.C.</em></a>, NREL Technical Report (2016) </p></li>
                      </ul>
                  <h2>Wave Energy Fundamentals</h2>
                    <ul>
                    <li><p><a href="https://doi.org/10.1017/CBO9780511754630"><em>Ocean Waves and Oscillating Systems: Linear Interactions Including Wave-Energy Extraction</em></a>, Cambridge University Press (2002) </p></li>
                        </ul>
 </section>
                </Col>
              </Row>
          </Container>
        </section>
      </div>
    )
  }
}

export default References