import React from 'react'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import logo from "../img/nrel-logo@2x-01.png"

export default function () {
    return(
        <header id="banner">
          <div className="banner-logo">
              <Container fluid="xxl">
                  <div className="row">
                      <div className="HeaderName col-md-6">
                          <Link to="/">
                          Technology Performance Level Assessment: <br/>
                          Wave Energy Converters
                          </Link>
                      </div>
                      <div className="HeaderImage col-md-6 justify-content-end">
                        <a href="https://www.nrel.gov/">
                          <img src={logo} alt="NREL Logo" />
                        </a>
                      </div>
                  </div>
              </Container>
          </div>
        </header>
    )
  }