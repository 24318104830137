import React from 'react'
import { Container, Row, Col} from 'reactstrap'
import '../styles/Page.css'

class Definitions extends React.Component {
  componentDidMount = () => {
    document.title = 'Definitions | Technology Performance Level Assessment | NREL'
  }

  render = () => {
    return (
      <div className="Page">
       

        <div>
          <Container className="Body">
              <Row>
                <Col md={12}>

                  <h1>Technical Performance Level Definitions</h1>
                  <p className="lead">This tool determines technology performance level, as defined in the table below.</p>
                
                  <div className="table-responsive">
  <table className="table" id="definitions">
    <thead>
      <tr>
        <th valign="bottom" scope="col" style={{width: "29%"}}>Range</th>
        <th valign="bottom" scope="col" style={{width: "6%"}}>Level</th>
        <th valign="bottom" scope="col" style={{width: "65%"}}>Definition</th>
      </tr>
    </thead>
    <tbody>
      <tr className="TRL1-0">
        <td rowspan="3"><p><strong>Low</strong></p>
        <p>Technology is not economically viable.</p></td>
        <td>1</td>
        <td><p>Majority of key performance characteristics and cost drivers do not satisfy, and present
        a barrier to, potential economic viability.</p></td>
      </tr>
      <tr className="TRL1-0">
        <td>2</td>
        <td><p>Some key performance characteristics and cost drivers do not satisfy potential economic viability.</p></td>
      </tr>
      <tr className="TRL1-0">
        <td>3</td>
        <td><p>Minority of key performance characteristics and cost drivers do not satisfy potential economic viability.</p></td>
      </tr>
      <tr className="TRL1-1">
        <td rowspan="3"> <p><strong>Medium</strong></p>
        <p>Technology features some characteristics for potential economic viability under distinctive market and operational conditions.  Technological or conceptual improvements may be required.</p>
        </td>
        <td>4</td>
        <td>To achieve economic viability under distinctive and favorable market and operational conditions, key technology implementation and fundamental conceptual improvements are required.</td>
      </tr>
      <tr className="TRL1-1">
        <td>5</td>
        <td>To achieve economic viability under distinctive and favorable market and operational conditions, key technology implementation improvements are required.</td>
      </tr>
      <tr className="TRL1-1">
        <td>6</td>
        <td>Majority of key performance characteristics and cost drivers satisfy potential economic viability under distinctive and favorable market and operational conditions.</td>
      </tr>
      <tr className="TRL1-2">
        <td rowspan="3"><p><strong>High</strong></p>
        <p>Technology is economically viable and competitive as a renewable energy form.</p></td>
        <td>7</td>
        <td>Competitive with other renewable energy sources given favorable support mechanism.</td>
      </tr>
      <tr className="TRL1-2">
        <td>8</td>
        <td>Competitive with other energy sources given sustainable support mechanism.</td>
      </tr>
      <tr className="TRL1-2">
        <td>9</td>
        <td>Competitive with other energy sources without special support mechanism.</td>
      </tr>
    </tbody>
  </table>
</div>
                
                </Col>
              </Row>


          </Container>
        </div>
      </div>
    )
  }
}

export default Definitions
