import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import '../styles/Page.css'

class AccountPage extends React.Component {
  componentDidMount = () => {
    document.title = 'My Account | Technology Performance Level Assessment | NREL'
  }

  render = () => {
    return (
      <div className="Page">
       

        <section>
          <Container className="Body">
              <Row>
                <Col md={12}>
        <h1>My Account</h1>
                  <p class="lead">Account Page Content</p>
                </Col>
              </Row>
          </Container>
        </section>
      </div>
    )
  }
}

export default AccountPage