import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'

import { saveAssessment, loadAssessment } from '../actions'

class Navigation extends React.Component {
    state = {
        showCollapsedMenu: false
    }

    toggleMenu = () => {
        this.setState({
          showCollapsedMenu: !this.state.showCollapsedMenu
        })
    }

    render () {
        const showMenu = (this.state.showCollapsedMenu) ? "show" : "" ;

        return (
            <div className="program-header MainNavigation">
              <Container fluid="xxl">
                  <nav className="navbar navbar-expand-md navbar-light">
                      <div id="program-menu" className="mr-auto">
                          <button
                              onClick={this.toggleMenu}
                              className="custom-toggler navbar-toggler"
                              type="button"
                              data-toggle="collapse"
                              data-target="#topnav-collapse"
                              aria-controls="topnav-collapse"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                          >
                              <span className="navbar-toggler-icon"></span>
                              <span className="navbar-toggle-label">
                                  Menu
                              </span>
                          </button>
                      </div>
        
                      <div id="topnav-collapse" className={"collapse navbar-collapse " + showMenu} >
                          <ul className="nav navbar-nav">
                              <li data-topnav="link2" className="nav-item">
                                <Link to="/about"className="nav-link">
                                    About
                                </Link>
                              </li>
                              <li data-topnav="link2" className="nav-item">
                                <Link to="/assessment"className="nav-link">
                                    Assessment Tool
                                </Link>
                              </li>
                              <li data-topnav="link3" className="nav-item">
                                <Link to="/definitions"className="nav-link">
                                    Definitions
                                </Link>
                              </li>
                              <li data-topnav="link4" className="nav-item">
                                <Link to="/references"className="nav-link">
                                    References
                                </Link>
                              </li>
                              <li data-topnav="link5" className="nav-item">
                                <Link to="/contact"className="nav-link">
                                    Contact Us
                                </Link>
                              </li>
                          </ul>
                          <ul className="nav navbar-nav ml-auto justify-content-end">    
                              <li data-topnav="link6" className="nav-item">
                                  <button className="nav-link" onClick={this.props.saveAssessment} >
                                    Save
                                  </button>
                              </li>
                              <li data-topnav="link7" className="nav-item">
                                  <button className="nav-link" onClick={this.props.loadAssessment} >
                                    Upload
                                  </button>
                              </li> 
                          </ul>
                      </div>
                  </nav>
              </Container>
            </div>
        )
    }
}

const mapDispatchToProps = {
    saveAssessment,
    loadAssessment
};

export default connect(null, mapDispatchToProps)(Navigation)