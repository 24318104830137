export const PAGE_LOADED = 'PAGE_LOADED'
export const SET_SCORES = 'SET_SCORES'
export const SET_QUESTION_SCORE = 'SET_QUESTION_SCORE'
export const OMIT_QUESTION = 'OMIT_QUESTION'
export const RESTORE_QUESTION = 'RESTORE_QUESTION'
export const OPEN_SECTION = 'OPEN_SECTION'
export const OPEN_SUBSECTION = 'OPEN_SUBSECTION'
export const OPEN_SUBSUBSECTION = 'OPEN_SUBSUBSECTION'
export const CLOSE_SECTION = 'CLOSE_SECTION'
export const CLOSE_SUBSECTION   = 'CLOSE_SUBSECTION'
export const CLOSE_SUBSUBSECTION = 'CLOSE_SUBSUBSECTION'
export const SAVE_UNCERTAINTY = 'SAVE_UNCERTAINTY'
export const SAVE_COMMENT = 'SAVE_COMMENT'
export const SAVE_ASSESSMENT = 'SAVE_ASSESSMENT'
export const LOAD_FROM_FILE = 'LOAD_FROM_FILE'
export const SET_GROUP = 'SET_GROUP'