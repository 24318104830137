let QUESTIONS = []

const EXAMPLE = {
    "group": 0, 
    "section": 0,
    "subsection": 0,
    "subsubsection": 0,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
}

// Group - Cost of Energy - Section CAPEX - Subsection DESIGN - (General Question)
QUESTIONS.push({
    ...EXAMPLE,
    "id": 0,
    "subsubsection": 0,
    "text":"Are the WEC subsystems designed for the expected extreme loads and motion, for the lifetime operating loads,and for the operational environment? Are all components mature technology with a history of use in the marine environment?",
    "guidance":"Approach this question from the perspective of and impact on CapEx. For this question, keeping in mind that this is a low-technology-readiness-level system, look at the design methods, environmental conditions, and design assumptions used to design and evaluate the WEC. Are they sufficient, or have they missed important aspects that will impact the CapEx of the design? If any components (e.g., umbilicals or structural components) are not well designed, this is the opportunity to catch this. <br/><br/>For low-technology-readiness-level assessment, it is sufficient for a developer to mention that loads, safety margins, etc., will be designed per design standards to score high. At this low-technology-readiness-level assessment, a detailed structural design should not be expected. Instead, to achieve a high score, the developer must supply initial calculations with loads, structural estimates, and identification of load paths and critical components. The developer must also supply sufficient and appropriate supporting information—such as the design basis, safety margins, and design standards—that will be used in future design.",
    "low_text":"Some components are expected to fail in extreme load conditions or during the life of the system, or loads and motions are not well known.",
    "medium_text":"Same as high except the loads and conditions are not as well characterized or some uncertainty exists within the structural reliability modeling. Components and subsystems are designed with easy access for replacement.",
    "high_text":"All WEC subsystems and their components have safety margins for extreme load and motion conditions, have fatigue lives expected to exceed system life, and are designed and approved for the environment they operate in. Loads and conditions are well characterized.",
    "invalidates": "designedForLoadQuestion",
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 1,
    "subsubsection": 1,
    "text": "In production volumes, what is the estimated manufactured and assembled cost of the WEC device in dollars per rated megawatt? This is for the WEC device only and does not include balance-of-station or development costs. Rated power is defined here as the maximum sustained output of the WEC device to the collection system.",
    "guidance": "Envision the WEC at full commercial production. Will the costs of the components of the primary WEC body (e.g., structure, power take-off, power conversion and conditioning) and the costs to assemble the WEC be low enough to enable the WEC to be competitive with conventional utility-scale power generation? At this technology readiness level, a high-level estimate is sufficient and should provide traceable cost estimates of all major components and assembly steps.",
    "high_text": "The cost estimate for the WEC is approximately $1,000 k/MW.",
    "medium_text": "The cost estimate for the WEC is approximately $2,000 k/MW.",
    "low_text": "The cost estimate for the WEC is approximately $4,000 k/MW.",
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 2,
    "subsubsection": 1,
    "text": "Are the components difficult to source, made of specialty material (e.g., very high cost, unknown properties for use/environment, or specially made/ordered), or not suitable for mass-manufacturing (e.g., difficult to work with or not suitable for conventional manufacturing methods)?",
    "guidance": "Specialty materials, hard-to-find components, and complex manufacturing can add significantly to CapEx. These should be avoided or minimized; however, designs that use innovative materials, manufacturing methods, or components and that have defensible solutions for volume production or reduced material costs should not be penalized. Similarly, a design should not be penalized if it relies on a feasible technology breakthrough.",
    "high_text": "All components are commercially available off the shelf or can be easily mass-manufactured using existing supply chains or fabrication techniques.",
    "medium_text": "Less than 20% of the value is components that require specialty materials, manufacturing equipment, or techniques.",
    "low_text": "More than 20% of the value is components that require specialty materials, manufacturing equipment, or techniques.",
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 3,
    "subsubsection": 1,
    "text": "Will the device experience large structural loads because of breaking waves, large waves, or other environmental forces, and will large structural components be needed to resist that force?",
    "guidance": "This question assesses how much additional structure, above what is needed during expected operation, is needed to resist extreme events. Designs should minimize material costs beyond what is needed for operation.",
    "high_text": "The structure will not experience large structural loads during extreme events because of design or a mitigation strategy such as submergence. Mitigation or design will keep extreme loads under 120% of maximum operating loads.",
    "medium_text": "The structure will experience some high loading because of extreme events such as breaking waves but has a mitigation strategy to limit the loads or the shape is designed to minimize these loads. Extreme loads do not exceed 160% of maximum operating loads.",
    "low_text": "The device will experience large structural loads and does not have a mitigation strategy to avoid those loads other than through direct structural resistance."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 4,
    "subsubsection": 1,
    "text": "Does the device need multiple configurations to limit stress in high-energy situations, increase energy capture, or meet other requirements? How much additional equipment (e.g., actuators, sensors) and structure, beyond what are needed for energy capture in a static configuration, are required to support the transformation?",
    "guidance": "Many WEC incorporate some form of configuration change to adapt to operating and extreme environmental conditions. Changes may reduce load, increase energy capture, or accommodate another goal, such as personal transfer. All configuration changes should be safe and robust and be as simple as possible. Ideally, the cost of the equipment needed for transformation should reduce CapEx from the WEC with a static configuration.",
    "high_text": "No configuration change is needed, or the cost of additional equipment, less income from additional energy production, reduces the cost of the WEC from its static configuration.",
    "medium_text": "The additional cost, less income from additional energy production, adds less than 10% to CapEx.",
    "low_text": "The additional cost, less income from additional energy production, adds more than 10% to CapEx."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 5,
    "subsubsection": 2,
    "text": "Are the components difficult to source, made of specialty material (e.g., very high cost, unknown properties for use/environment, or specially made/ordered) or not suitable for mass-manufacturing (e.g., difficult to work with or not suitable for conventional manufacturing methods)?",
    "guidance": "PTO or equivalent subsystems are complex, but they must be assembled from readily sourced parts and in a straightforward manner. <br/><br/>Looking to the future toward a commercial design, is there a path by which the PTO or equivalent system can be readily manufactured at high volume and reasonable cost, or are there significant issues or risks that must be addressed before the design should proceed to higher technology readiness levels?",
    "high_text": "All components are commercially available off the shelf or can be easily mass-manufactured using existing supply chains or fabrication techniques. ",
    "medium_text": "Less than 20% of the value is components that require specialty materials, manufacturing equipment, or techniques.",
    "low_text": "More than 20% of the value is components that require specialty materials, manufacturing equipment, or techniques."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 6,
    "subsubsection": 2,
    "text": "Within the WEC, what is the target ratio of instantaneous peak to mean power for the energy conversion drivetrain? <br/><br/>Instantaneous peak power is defined here as the maximum output of the energy conversion component. For mean power, the manufacturer must provide a power matrix and specify two wave spectrums used to calculate annual energy and then mean power for each spectrum. The wave spectrums must have an average of 15 kW/m and 30 kW/m energy and be representative of actual resource distributions in the real world.",
    "guidance": "PTOs or equivalent subsystems are typically expensive, and they should operate at a high fraction of their mechanical and structural design. For example, wind generators typically operate above 90% of their design load once rated power is achieved, even in turbulent conditions. This is a ratio near 1.<br/><br/>At conditions that yield rated power, a PTO that operates, on average, at a small fraction of its structural and electrical design loads can drive up CapEx. Ideally, simulation or model test information is provided to quantify this. For this question, estimate the impact on CapEx.",
    "high_text": "Ratio is below 5.0, and only one component is required to be oversized at or near this ratio relative to the mean power.",
    "medium_text": "Ratio is between 5.0 and 10.0, and several components are required to be oversized at or near this ratio relative to the mean power.",
    "low_text": "Ratio is higher than 10.0, and the entire drivetrain needs to be oversized at or near this ratio relative to the mean power."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 7,
    "subsubsection": 2,
    "text": "How many conversion steps are there between the absorbing element and the component that produces the transportable power (e.g., a gearbox, alternator, power conditioning in the WEC, hydraulic pump or motor)? <br/><br/>Transportable power is electrical energy or pressurized working fluid, whichever is exported out of the WEC to the collection system.",
    "guidance": "The number of conversion steps increases CapEx and decreases conversion efficiency, thus requiring more structure for energy capture. The PTO or equivalent subsystem must be as low-cost and efficient as possible to achieve efficiencies expected in other power generation fields. Consider the CapEx impact of the number of conversion steps and on efficiency in this question.",
    "high_text": "Two or less, with a net efficiency of at least 90%.",
    "medium_text": "Three to four, with a net efficiency of 80%–90%.",
    "low_text": "More than four, with a net efficiency of less than 80%."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 8,
    "subsubsection": 2,
    "text": "What is the cost of the system that converts mechanical power to usable power?",
    "guidance": "Include generator but exclude inverter, step-up transformer, and frequency conditioner.",
    "high_text": "The cost estimate for the WEC is approximately $100,000/MW.",
    "medium_text": "The cost estimate for the WEC is approximately $250,000/MW.",
    "low_text": "The cost estimate for the WEC is approximately $500,000/MW."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 9,
    "subsubsection": 2,
    "text": "What is the cost of power conditioning equipment such as inverters and transformers or pumps and accumulators?",
    "guidance": "",
    "high_text": "The cost estimate for the WEC is approximately $50,000/MW.",
    "medium_text": "The cost estimate for the WEC is approximately $100,000/MW.",
    "low_text": "The cost estimate for the WEC is approximately $200,000/MW."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 10,
    "subsubsection": 3,
    "text": "For cable-based mooring systems, what is the ratio of the expected watch circle (largest characteristic excursion of WEC) to the expected footprint (length to anchors, L)?",
    "guidance": "Watch circle size can drive the spacing of WECs, the length of station-keeping equipment, and power transmission components. Generally, the larger a watch circle, the higher the balance of station capital expenditure.",
    "high_text": "w/L <0.2 (includes devices with rigid attachment to bottom) ",
    "medium_text": "0.3 <w/L <0.8 ",
    "low_text": " Any mooring concept with w/L >1"
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 11,
    "subsubsection": 3,
    "text": "For an expected depth, is the station-keeping system inexpensive and relatively simple?",
    "guidance": "The capital cost of the mooring should be a small portion of the overall system CapEx, unless the overall WEC CapEx is much lower $1,000,000/MW.",
    "high_text": "The station-keeping system uses conventional low-cost mooring equipment or other components that are readily available at low cost. The expected station-keeping cost will be less than 10% of CapEx. The layout of the station-keeping system is relatively simple and easy to build.",
    "medium_text": "The station-keeping system uses one or more components that can be considered high-cost for moorings. The expected station-keeping cost will be less than 20% of CapEx.",
    "low_text": "The expected mooring system would account for more that 25% of CapEx."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 12,
    "subsubsection": 3,
    "text": "Does the cost and complexity of the station-keeping design scale well with depth?",
    "guidance": "CapEx can increase with depth, even if the device output remains the same. For example, for a mooring system, the lengths of lines increase with depth, and more buoyancy may need to be added to the WEC to support the additional weight of the longer lines. The impact of depth on CapEx must be quantified.",
    "high_text": "The cost of the mooring system scales less than linearly with depth.",
    "medium_text": "The cost of the mooring system scales linearly with depth.",
    "low_text": "The cost of the mooring system increases rapidly with depth, greater than a linear trend."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 13,
    "subsubsection": 4,
    "text": "For the controller, how much additional equipment (e.g., actuators and sensors) and structure, beyond what is needed for energy capture in an uncontrolled configuration, is required?",
    "guidance": "Control systems can increase power capture, but they can be complicated and require significant information. Ideally, controllers should increase energy capture and reduce loads without adding significant cost. If controllers can reduce load, they can also reduce structural costs. This balance should be included here. If expensive sensors are needed, distribute the costs among the number of buoys that will use those sensors.",
    "high_text": "No control is used, or the CapEx of the actuators and sensors adds less than 5% to net Capex.",
    "medium_text": "The additional cost adds more than 5% and less than 10% to net Capex.",
    "low_text": "The additional cost adds more than 10% to net CapEx."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 14,
    "subsubsection": 4,
    "text": "For the system used to transport power from the WEC to the grid, is the system reasonably priced, and does it scale well with distance and array size?",
    "guidance": "Transmission costs should not dominate the CapEx unless overall WEC CapEx is much lower $1,000,000/MW. <br/><br/>Consider array sizes to be: <ul><li>Small: Up to 10 MW</li> <li>Medium: 10 MW–100 MW</li> <li>High: 100+ MW.</li></ul> Consider distance to shore to be: <ul><li>Short: <10 km</li> <li>Medium: 10 km–50 km</li> <li>Long: >50 km.</li></ul>",
    "high_text": "The transmission from the WEC to the grid adds less than 5% to net CapEx, for small to large array sizes and distance to shore. ",
    "medium_text": "The transmission from the WEC to the grid adds less than 5% to net CapEx, for medium to large array sizes and medium to short distance to shore.",
    "low_text": "The transmission from the WEC to the grid adds less than 5% to net CapEx, for large array sizes and short distance to shore."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 15,
    "subsection": 1,
    "subsubsection": null,
    "text": "Considering the entire WEC, are systems or components custom-manufactured outside of expected or common practices? This could include custom generators, nontypical manufacturing processes, and non-commercial off-the-shelf components where commercial off-the-shelf components are common.",
    "guidance": "Envision the WEC at full commercial production. Are there any components that cannot be mass-manufactured in a straightforward or cost-effective manner, and, as such, will not allow economies of scale? Consider complex geometries, special-order components (unique generator designs), and designs that require customization for each site.",
    "high_text": "No specialized manufacturing is required, or few components (e.g., inverter, alternator) have volume cost breaks available at 100 devices per year. (Electrical devices typically have a 100-piece discount.) ",
    "medium_text": "More than 20% of cost is in specialty components or requires over 100 devices per year for volume pricing discounts.",
    "low_text": "More than 40% of cost is in specialty components or requires over 500 devices per year for volume pricing discounts."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 16,
    "subsection": 1,
    "subsubsection": null,
    "text": "What are the manufacturing facility requirements? Can manufacturing and assembly be performed local to deployment sites or the WEC farm location? Consider lifting, transport, launching, power, and enclosures/environmental conditioning.",
    "guidance": "Ideally, the WEC should be able to be manufactured using conventional methods and equipment. Costly shore facilities, such as dry docks or heave-lift cranes, should be avoided to minimize cost.",
    "high_text": "The WEC can be manufactured in typical manufacturing facilities and readily assembled in a typical boatyard, or it can be manufactured and assembled in facilities expected in a coastal community that provides commercial fishing boat and large yacht servicing or onsite at the location of the farm.",
    "medium_text": "The WEC requires a dedicated custom-manufacturing facility, or assembly facility requirements are not common in a coastal community and likely available facilities would require significant upgrades or are only available at major ports or large shipyards.",
    "low_text": "The WEC requires dry-dock or floating dock (very specialized locations that are not highly translatable); harbor water depth must be deep."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 17,
    "subsection": 1,
    "subsubsection": null,
    "text": "What expertise is needed from the workforce (dependent on material type, level of tolerances that must be achieved, specialized safety, customized molds)?",
    "guidance": "To minimize cost, WEC manufacturing and assembly should not require highly specialized skills, hazardous materials, or dangerous work conditions.",
    "high_text": "The majority of manufacturing and assembly work does not require highly specialized skills and does not pose safety issues (such as dealing with hazardous materials or working in confined spaces). Common skills, such as welding low-carbon steel and performing electrical and plumbing work, are not considered specialized.",
    "medium_text": "Some work requires more specialized skills (such as welding specialized metals) or requires critical weld quality or other specialized skills not common in coastal communities. Special safety considerations (such as working in confined spaces or use of divers) occur, but they are easily managed.",
    "low_text": "Much of the work requires specialized skills not likely available in a coastal community, or hazardous work that necessitates significant mitigation efforts (such as working with toxic and hazardous materials) is required."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 18,
    "subsection": 1,
    "subsubsection": null,
    "text": "Are any of the major structural or shell components complex to form?",
    "guidance": "Complex shapes are often much more expensive to manufacture than relatively simple shapes.",
    "high_text": "All of the surfaces and structure are easy to develop using existing techniques. ",
    "medium_text": "Some of the surfaces and structure require special forming or significant labor.",
    "low_text": "A significant amount of the structure and shell requires special forming or extra labor."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 19,
    "subsection": 2,
    "subsubsection": null,
    "text": "Are any components not readily manufacturable locally, large, and therefore require overland transport with specialized vehicles or logistics (i.e., cannot be transported on conventional trains or trucks, not available from a shoreside manufacturer)?",
    "guidance": "When WECs need to be transported from manufacturing facilities, they should  use conventional methods of transport, such as roads and rails, and be assembled using equipment found in conventional  coastal facilities (shipyards). Ships are a transport option as long as many devices can be fit on a ship that can navigate to and offload in typical coastal facilities. Huge cargo ships may not be a viable option because they are limited to commercial port facilities and would require secondary transport to the assembly location.",
    "high_text": "Component transportation is by nonspecialized boat, railway, or road and requires no upgrade to existing infrastructure (such as widening roads) and no special permissions or precautions (e.g., permission from local authorities or police, special speed limit, or special escort).",
    "medium_text": "Component transportation is by specialized boat, railway, or road and requires no upgrade to existing infrastructure (such as widening roads) but does require special permission or precautions (e.g., permission from local authorities or police, special speed limit, or special escort).",
    "low_text": "Component transportation requires an upgrade to existing infrastructure."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 20,
    "subsection": 3,
    "subsubsection": null,
    "text": "Can the WEC device be assembled fully onshore or at the side of a pier in a harbor and towed easily and safely to the installation site? Or can it be easily assembled offshore in a range of weather conditions?",
    "guidance": "Overall, can the WEC be deployed from coastal facilities (shipyards) using commonly available vessels, or will special/expensive vessels need to be brought in?",
    "high_text": "The WEC device can be fully assembled on land, launched with commonly available cranes or straddle lifts, and towed to the installation site for hookup, or it can be easily assembled offshore without specialized handling equipment.",
    "medium_text": "The WEC device can be assembled in the harbor, preferably at the pier, using commonly available cranes and towed to the site for hookup. ",
    "low_text": "On-site assembly is required. Specialized vessels are required."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 21,
    "subsection": 3,
    "subsubsection": null,
    "text": "How fast can the WEC be transported from the dock to the installation site, and how weather- or sea state-dependent is the tow?",
    "guidance": "The cost and risk of towing are directly proportional to towing time. The longer the tow, the higher the cost. Also, costs increase when tows are limited to lower sea states because there are fewer weather windows for tow-out and higher standby costs.",
    "high_text": "The WEC does not need to be towed or can be towed at speeds that exceed 4 knots and in Sea State 5 (Beaufort Scale).",
    "medium_text": "The WEC can be towed at speeds of 3–4 knots and Sea State 4.",
    "low_text": "The WEC can be towed at speeds less than 3 knots and sea states less than 4."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 22,
    "subsection": 3,
    "subsubsection": null,
    "text": "What type of installation vessels will be needed for 1 MW of capacity? This can include multiple devices up to 1 MW if they can be deployed in one trip. Guidance should scale for larger devices.",
    "guidance": "Larger support vessels cost more. Costs can increase exponentially with size.",
    "high_text": "Common workboat less than 100 ft with conventional lift gear, such as less than 50,000 lb. capability.",
    "medium_text": "Larger workboat (less than 300 ft), multiple workboats (less than 100 ft) or workboats with high lifting capability.",
    "low_text": "Very large workboat (more than 300 ft) or with special capabilities such as dynamic positioning."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 23,
    "subsection": 3,
    "subsubsection": null,
    "text": "How difficult is the installation (excluding the installation of the station-keeping system)? Consider all steps and equipment needed to install the WEC.",
    "guidance": "WEC installation can be expensive if multiple large vessels, special vessels, divers, or many steps are needed. This also severely limits sea states and operating hours. Ideally, a WEC should be able to be quickly installed with minimal vessels without using divers or underwater vehicles. Steps should be straightforward, low-risk, and reversible.",
    "high_text": "All operations can be conducted from the installation vessel and, at most, two rigid-hulled inflatable boats or equivalent in seas beyond Sea State 4 (Beaufort Scale).",
    "medium_text": "Operations require special equipment (such as smaller utility remotely operated vehicles), multiple work vessels/tugs, or are limited to Sea State 3 or below.",
    "low_text": "Operations require divers, expensive support equipment (such as work-class remotely operated vehicles), or are limited to Sea State 4 or below."
})

QUESTIONS.push({
    ...EXAMPLE,
    "id": 24,
    "subsection": 3,
    "subsubsection": null,
    "text": "What are the weather window requirements for installation?",
    "guidance": "WEC installation can be expensive. If installation can only be done in a narrow weather window—even if installation is quick or requires few vessels—cost can quickly rise if  assets are on hold waiting for the weather. The larger the weather window, the more devices that can be installed in a given period, thus reducing costs.",
    "high_text": "All operations can be conducted at Sea State 4 (Beaufort Scale) and possibly beyond. ",
    "medium_text": "Operations are limited to Sea State 3 or below.",
    "low_text": "Operations are limited to Sea State 2 or below."
})


/// COE - Opex
const COE_OPEX_DEFAULT = ({
    "group": 0, 
    "section": 1,
    "subsection": 4,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...COE_OPEX_DEFAULT,
    "id": 25,
    "text": "Are the WEC subsystems designed for the expected extreme loads, the operating loads for the lifetime of the system, and the operational environment?",
    "guidance": "How often will the WEC need to be repaired, and how much will those repairs cost? For this question, keeping in mind that this is a low-technology-readiness-level system, look at the design methods, environmental conditions, and design assumptions used to design and evaluate the WEC. Are they sufficient, or have they missed important aspects that will impact the OpEx of the design? If any components (e.g., umbilicals, structural components) are not well designed, this is the opportunity to catch this. <br/><br/>At this low-technology-readiness-level assessment, a detailed structural design should not be expected. Instead, to achieve a high score, the developer must supply initial calculations with loads, structural estimates, and identification of load paths and critical components. The developer must also supply sufficient and appropriate supporting information—such as the design basis, safety margins, and design standards—that will be used in future design.",
    "high_text": "All WEC subsystems and their components have safety margins for extreme load and motion conditions, have fatigue lives expected to exceed system life, and are designed and approved for the environment they operate in. Loads and conditions are well characterized.",
    "medium_text": "Same as high except the loads and conditions are not as well characterized or some uncertainty exists within the structural reliability modeling. Components and subsystems are designed with easy access for replacement.",
    "low_text": "Some components are expected to fail in extreme load conditions or during the life of the system, or loads and motions are not well known.",
    "invalidated_by": 0 
})

QUESTIONS.push({
    ...COE_OPEX_DEFAULT,
    "id": 26,
    "text": "What are the known failure modes and frequency of failure for WEC subsystems and their components? What is the level of confidence for failure modes and frequency? What are the consequences of failure?",
    "guidance": "The WEC should be reliable and use proven technologies or low-risk technologies to minimize OpEx costs. ",
    "high_text": "Most or all subsystems and components are mature technologies that have been used in similar environments. Mean time between failures for each component is more than planned WEC life, or component is low-cost and easy to remove and replace. Components that may fail do not endanger WEC for loss or further damage because of failing safe or redundancy.",
    "medium_text": "Some new technology components or components with no history of use in this environment are used, so failure rate data are not reliable for this application. Components with expected failures are expensive. Removal and replacement of parts can be difficult on location, or specialized boats or equipment are required. Failure results in full loss of function or increased danger to the WEC.",
    "low_text": "Several components are new designs with no operational history in this environment, or there is high difficulty in replacing or repairing failed parts. Some parts that may fail result in significant increase in the probability of loss or further damage to the WEC."
})

QUESTIONS.push({
    ...COE_OPEX_DEFAULT,
    "subsection": 5,
    "id": 27,
    "text": "What is the most difficult remove-and-replace maintenance task expected on the WEC during its operating life—either due to mass and size that requires a crane or due to the coordination of divers and lift equipment?",
    "guidance": "Offshore maintenance can be expensive if large work boats and other special equipment is needed. The WEC should require minimal intervention to minimize OpEx costs. This question is intended to consider the most complex and expensive task that is expected/likely to occur on a WEC. Rare intervention tasks that are very unlikely to occur during the lifetime of a WEC should not be considered. Approach this question from the perspective of and impact on OpEx.",
    "high_text": "No high-mass or -volume items are expected to be replaced in the WEC lifetime. Maintenance can be performed from a conventional 100-ft work boat.",
    "medium_text": "Less than one failure of a high-mass item is expected per WEC lifetime, and it is easy to release and tow the WEC into a protected harbor with a dockside crane to facilitate the work. Release and tow could be accomplished with a 100-ft or less work vessel and an intermediate remotely operated vehicle.",
    "low_text": "There are components that will require heavy lift-equipped or other specialized vessels (e.g., dynamically positioned) or expensive support equipment (e.g., work-class remotely operated vehicles) for service on site when releasing the WEC and towing to a harbor would be a difficult or expensive option."
})

QUESTIONS.push({
    ...COE_OPEX_DEFAULT,
    "id": 28,
    "subsection": 5,
    "text": "What are the limiting sea states that allow maintenance access? How is relative motion between the WEC and work platform minimized? Or motion between the WEC and power take-off mooring?",
    "guidance": "Offshore maintenance of WECs can be expensive. If it can only be done in a narrow weather window—even if the task is quick or requires minimal support—cost can quickly rise if assets are on hold waiting for the weather.  The larger the weather window, the more devices that can be maintained in a given period, thus reducing costs and device downtime.",
    "high_text": "The device can be serviced in Sea State 4 (Beaufort Scale) and greater.",
    "medium_text": "The device can be serviced in Sea State 3 or less.",
    "low_text": "The device can be serviced in Sea State 2 or less."
})

//

/// COE - Performance
const COE_PERFORMANCE_DEFAULT = ({
    "group": 0, 
    "section": 2,
    "subsection": 6,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 29,
    "text": "Can the WEC device absorb energy effectively over a range of wave resources that are expected for early development? Is the WEC insensitive to wave direction?",
    "guidance": "The WEC should not be limited to operating in a narrow resource found at limited sites but should be able to produce its nominal peak power for many different resources and, thus, at many different sites. Small variations in resource do not result in large variation in performance. Also, most sites do not have unidirectional waves, so WECs should be insensitive to wave direction.",
    "high_text": "The WEC can produce 80% plus of nominal peak power with a wave resource from 20 to 40 kW/m and from any direction.",
    "medium_text": "Nominal peak power is only possible over a narrower range that spans less than 10 kW/m or within 35 degrees of design.",
    "low_text": "Nominal peak power is only possible in a limited range of a high-wave resource, such as in excess of 30 kW/m or within 15 degrees of design."
})

QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 30,
    "text": "How efficient is the device at absorbing wave energy? Calculate the capture width ratio for two specified spectrums, and average the results. <br/><br/>The manufacturer must provide a power matrix and specify two wave spectrums used to calculate annual energy and then mean power for each spectrum. The wave spectrums must have an average of 15 kW/m and 30 kW/m energy and be representative of actual resource distributions in the real world. Capture width (CW) is the power divided by the resource (kW/(kW/m)). Capture width ratio is CW/B, where B is the characteristic dimension (diameter for a point device).",
    "guidance": "",
    "high_text": "The capture width ratio exceeds 1.",
    "medium_text": "The capture width ratio is between 0.5 and 1",
    "low_text": "The capture width ratio is less than 0.5."
})

QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 31,
    "text": "Is the theoretical limit for energy absorption by the wave power collecting systems units large (number of degrees of freedom and types, orientation, Budal limit)?",
    "guidance": "A WEC should absorb as much of the wave energy flux as possible. ",
    "high_text": "Power capture in 3+ degrees of freedom, dipole radiation pattern (terminator orientation), and large swept volume. (Budal limit does not impose significant cuts in power absorption.)",
    "medium_text": "Power capture in 2 degrees of freedom, any radiation pattern, and large swept volume. (Budal limit imposes some cuts in power absorption.)",
    "low_text": "Power capture in 1 degree of freedom, any radiation pattern, and limited swept volume. (Budal limit imposes significant cuts in power absorption.)"
})


QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 32,
    "text": "If applicable, how are the motion and range of the absorbing elements of the wave power collecting systems mechanically limited?",
    "guidance": "Limitations placed on the motion of the absorbing element can reduce energy capture, so consider any impact this may have on energy capture and conversion.",
    "high_text": "No limitation on the kinematic side of absorbed power; mechanical limitations imposed only through position control, for instance.",
    "medium_text": "Through a limitation in a degree of freedom that does not directly contribute to the kinematic side of absorbed power",
    "low_text": "Through a limitation on the kinematic side of absorbed power"
})

QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 33,
    "text": "Is the energy absorption by the wave power collecting systems sensitive to tidal height, tidal current, or wind?",
    "guidance": "Many areas with a viable wave resource also have tides, currents, and wind. Ideally, these should have no impact on WEC energy absorption. ",
    "high_text": "Minimally sensitive to tidal height, tidal current, or wind impacting WEC performance.",
    "medium_text": "Any of the three conditions or a combination of them within common values can result in a reduction in captured energy of between 15% and 30% during the impact of these conditions.",
    "low_text": "Any of the three conditions or a combination of them within common values can result in a reduction in captured energy of greater than 30% during the impact of these conditions."
})

QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 34,
    "text": "What is the influence of the station-keeping system on energy absorption?",
    "guidance": "",
    "high_text": "The station-keeping system does not negatively influence energy absorption.",
    "medium_text": "The station-keeping system has limited impact on reducing energy capture.",
    "low_text": "The mooring system restricts the power absorption."
})

QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 35,
    "subsection": 7,
    "subsubsection": 5,
    "text": "How many conversion steps are there between the absorbing element and the component that produces the transportable power? How many times is the form of the energy significantly changed? What is the design average combined energy conversion and transmission efficiency?<br/><br/>This does not include the efficiency of converting wave energy to mechanical energy. Use average energy input over average energy output over a year of operation in the design operating resource.",
    "guidance": "The number of conversion steps decreases conversion efficiency. The power take-off or equivalent subsystem must be as efficient as possible to achieve efficiencies expected in other power generation fields. <br/><br/>Transportable power is electrical energy or pressurized working fluid, whichever is exported out of the WEC to the collection system. Insignificant changes include things like simple changes of direction (e.g., a pulley or bell-crank), while significant changes include magnitude exchanges (e.g., a gearbox changing torque and speed or a transformer changing voltage and current), changes in form (e.g., linear mechanical to rotational mechanical), or changes in type (e.g., mechanical to electrical, hydraulic to pneumatic). A complete gearbox will count as one change regardless of how many stages it contains, and a complete power converter (i.e., rectifier-inverter combination) will count as one change regardless of its electrical configuration, even if the rectifier and inverter are physically separate.",
    "high_text": "Two or less with a net efficiency of at least 90%",
    "medium_text": "Three to four with a net efficiency of 80%–90%",
    "low_text": "More than four with a net efficiency of less than 80%",
    "invalidated_by": 7
})

QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 36,
    "subsection": 7,
    "subsubsection": 5,
    "text": "Within the WEC, what is the target ratio of instantaneous peak to mean power for the energy conversion drive train? <br/><br/>Instantaneous peak power is defined here as the maximum output of the energy conversion component. For mean power, the manufacturer must provide a power matrix and specify two wave spectrums that were used to calculate annual energy and then mean power for each spectrum. The wave spectrums must have an average of 15 kW/m and 30 kW/m energy and be representative of actual resource distributions in the real world.",
    "guidance": "Power take-offs or equivalent subsystems are typically expensive, and they should operate at a high fraction of their mechanical and structural design. For example, wind generators typically operate above 90% of their design load once rated power is achieved, even in turbulent conditions. This is a ratio near 1 .<br/><br/>At conditions that yield rated power, a power take-off that operates, on average, at a small fraction of its structural and electrical design loads can drive up CapEx. Ideally, simulation or model test information is provided to quantify this.",
    "high_text": "Ratio is below 5.0, and only one component is required to be oversized at or near this ratio relative to the mean power.",
    "medium_text": "Ratio is between 5.0 to 10.0, and several components are required to be oversized at or near this ratio relative to the mean power.",
    "low_text": "Ratio is higher than 10.0, and the entire drivetrain needs to be oversized at or near this ratio relative to the mean power.",
    "invalidated_by": 6
})


QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 37,
    "subsection": 7,
    "subsubsection": 6,
    "text": "How many conversion steps are there after the output from the individual WEC device to the point of connection? What is the design combined average energy efficiency of the conversion steps? Loses in the WEC are not included here.",
    "guidance": "The number of conversion steps decreases conversion efficiency. After the WEC, how many more times is the energy transformed, such as from AC to DC and then back to AC or hydraulic flow and velocity to mechanical power to electrical power? This does not include transmission (wire) losses because they are project/site-dependent.",
    "high_text": "Two or less and/or above 95% efficiency",
    "medium_text": "Three and/or above 90% efficiency",
    "low_text": "More than three and/or below 80% efficiency"
})


QUESTIONS.push({
    ...COE_PERFORMANCE_DEFAULT,
    "id": 38,
    "subsection": 7,
    "subsubsection": 6,
    "text": "For each part of the power collection system, what is the target ratio of peak to mean power? Are peak power surges smoothed within the WEC device, or is the power collection system required to handle the peak values?<br/><br/>In this question, peak power is instantaneous power during the wave cycle, and mean power is the average power. The manufacturer must provide a power matrix and specify two wave spectrums that were used to calculate annual energy and then mean power for each spectrum. The wave spectrums must have an average of 15 kW/m and 30 kW/m energy and be representative of actual resource distributions in the real world.",
    "guidance": "Many conversion and transmission systems' performance degrades with pulsating flow, and oversized components are needed to handle the peaks. This question aims to account for the reduced efficiencies that occur because a pulsating flow is used instead of a steady flow.",
    "high_text": "All steps are below 4.0",
    "medium_text": "4.0 to 10.0, and not all components see the highest ratio.",
    "low_text": "Higher than 10.0, and all or most components see similar high ratios."
})


/// COE - Availability
const COE_AVAILABILITY_DEFAULT = ({
    "group": 0, 
    "section": 3,
    "subsection": 8,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...COE_AVAILABILITY_DEFAULT,
    "id": 39,
    "text": "Are the WEC subsystems designed for the expected extreme loads and motion, the lifetime operating loads, and the operational environment? Are all components mature technology with a history of use in the marine environment?",
    "guidance": "How often will the WEC be inoperable? For this question, keeping in mind that this is a low-technology-readiness-level system, look at the design methods, environmental conditions, and design assumptions used to design and evaluate the WEC. Are they sufficient, or have they missed important aspects that will impact availability? If any components (e.g., umbilicals, structural components) are not well-designed, this is the opportunity to catch this. <br/><br/>At this low-technology-readiness-level assessment, a detailed structural design should not be expected. Instead, to achieve a high score, the developer must supply initial calculations with loads, structural estimates, and identification of load paths and critical components. The developer must also supply sufficient and appropriate supporting information—such as the design basis, safety margins, and design standards—that will be used in future design.",
    "high_text": "All WEC subsystems and their components have safety margins for extreme load and motion conditions, have fatigue lives expected to exceed system life, and are designed and approved for the environment they operate in. Loads and conditions are well characterized.",
    "medium_text": "Same as high except the loads and conditions are not as well characterized, some uncertainty exists within the structural reliability modeling, and components and subsystems are designed with easy access for replacement.",
    "low_text": "Some components are expected to fail in extreme load conditions or during the life of the system, or loads and motions are not well known.",
    "invalidated_by": 0
})

QUESTIONS.push({
    ...COE_AVAILABILITY_DEFAULT,
    "id": 40,
    "text": "What are the limiting sea states that allow maintenance access? How is relative motion between the WEC and work platform minimized? Or motion between the WEC and power take-off mooring?",
    "guidance": "Offshore maintenance of WECs is often limited by weather. If it can only be done in a narrow weather window—even if the task is quick or requires minimal support—delays can quickly mount, rendering the WEC inoperable for long periods of time. <br/><br/>Downtime can be significantly reduced if sea state limitations for maintenance can be reduced. In terms of availability, how will access to the WEC for maintenance improve or reduce availability?",
    "high_text": "The device can be serviced in Sea State 4 (Beaufort Scale) and greater.",
    "medium_text": "The device can be serviced in Sea State 3 or less.",
    "low_text": "The device can be serviced in Sea State 2 or less.",
    "invalidated_by": 28
})

QUESTIONS.push({
    ...COE_AVAILABILITY_DEFAULT,
    "id": 41,
    "text": "What is the most difficult remove-and-replace maintenance task expected on the WEC during its operating life—either because of mass and size that requires a crane or coordination of divers and lift equipment?",
    "guidance": "Offshore maintenance can be expensive if large work boats and other special equipment are needed. If large, specialized assets are needed, it can take time to hire the equipment, and schedules can be delayed by months. <br/><br/>This question is intended to consider the most complex and expensive task that is expected/likely to occur on a WEC. Rare intervention tasks that are very unlikely to occur during the lifetime of a WEC should not be considered.",
    "high_text": "No high-mass or -volume items are expected to be replaced in the WEC lifetime. Maintenance can be performed from a conventional 100-ft work boat.",
    "medium_text": "Less than one failure per WEC lifetime of a high-mass item, and it is easy to release and tow the WEC into a protected harbor with a dockside crane to facilitate the work. Release and tow could be accomplished with a 100-ft or less work vessel and an intermediate remotely operated vehicle.",
    "low_text": "There are components that will require heavy lift-equipped or other specialized vessels (e.g., dnamically positioned) or expensive support equipment (e.g., work-class remotely operated vehicles) for service on-site when releasing the WEC and towing to a harbor would be a difficult or expensive option.",
    "invalidated_by": 27
})


/// Investment Opportunity - CAPEX UNCERTAINTY
const INVESTMENT_DEFAULT = ({
    "group": 1, 
    "section": 4,
    "subsection": 9,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})


QUESTIONS.push({
    ...INVESTMENT_DEFAULT,
    "id": 42,
    "text": "Are the WEC subsystems designed for the expected extreme loads and motion, for the lifetime operating loads, and for the operational environment? Are all components mature technology with a history of use in the marine environment?",
    "guidance": "How certain are the CapEx costs of the design? For this question, keeping in mind that this is a low-technology-readiness-level system, look at the design methods, environmental conditions, and design assumptions used to design and evaluate the WEC. Are they sufficient, or have they missed important aspects that will impact the uncertainty of the CapEx of the design? If any components (e.g., umbilicals, structural components) are not well designed, this is the opportunity to catch this. <br/><br/>At this low-technology-readiness-level assessment, a detailed structural design should not be expected. Instead, to achieve a high score, the developer must supply initial calculations with loads, structural estimates, and identification of load paths and critical components. The developer must also supply sufficient and appropriate supporting information—such as the design basis, safety margins, and design standards—that will be used in future design.",
    "high_text": "All WEC subsystems and their components have safety margins for extreme load and motion conditions, have fatigue lives expected to exceed system life, and are designed and approved for the environment they operate in. Loads and conditions are well characterized. ",
    "medium_text": "Same as high except the loads and conditions are not as well characterized or some uncertainty exists within the structural reliability modeling. Components and subsystems are designed with easy access for replacement. ",
    "low_text": "Some components are expected to fail in extreme load conditions or during the life of the system, or loads and motions are not well known.",
    "invalidated_by": 0
})

QUESTIONS.push({
    ...INVESTMENT_DEFAULT,
    "id": 43,
    "text": "Are any material types used in the WEC rare or located only in particular parts of the world? What material types are vulnerable to price fluctuations?",
    "guidance": "Commonly available materials have multiple sources in many countries, and this yields stable supply lines and prices. However, some materials (such as rare elements) may only be sourced from one or a few areas. If a significant portion of a device's CapEx is from such materials, the cost of the WEC can be directly related to the volatility of the rare material pricing.",
    "high_text": "Traditional, inexpensive material types are more than 90% of the WEC cost.",
    "medium_text": "20%–30% of the WEC cost is for expensive material types subject to price fluctuations (e.g., rare earth magnetic material).",
    "low_text": "More than 30% of the WEC cost is for expensive material types subject to price fluctuations (e.g., rare earth magnetic material)."
})

QUESTIONS.push({
    ...INVESTMENT_DEFAULT,
    "id": 44,
    "text": "Are new manufacturing capabilities or new workforce expertise needed to construct the WEC?",
    "guidance": "If manufacturing capabilities beyond existing commercial capabilities are needed (such as developing new automated welding processes) or if manufacturing skills that are not currently taught at trade schools and colleges are needed, this can cause uncertainty in the cost of manufacturing. If capabilities or skills don't currently exist but a clear and straightforward path to achieving them is certain, then consider them as capabilities/skills that do exist.",
    "high_text": "No",
    "medium_text": "Either new capabilities or new expertise are needed, but not both.",
    "low_text": "Yes"
})

const INVESTMENT2_DEFAULT = ({
    ...INVESTMENT_DEFAULT,
    "subsection": 10,
})

QUESTIONS.push({
    ...INVESTMENT2_DEFAULT,
    "id": 45,
    "text": "Are the WEC subsystems designed for the expected extreme loads and motion, for the lifetime operating loads, and for the operational environment? Are all components mature technology with a history of use in the marine environment?",
    "guidance": "How certain are the expected maintenance and repair costs? For this question, keeping in mind that this is a low-technology-readiness-level system, look at the design methods, environmental conditions, and design assumptions used to design and evaluate the WEC. Are they sufficient, or have they missed important aspects that will impact the uncertainty of the OpEx of the design?. If any components (e.g., umbilicals, structural components) are not well designed, this is the opportunity to catch this. <br/><br/>At this low-technology-readiness-level assessment, a detailed structural design should not be expected. Instead, to achieve a high score, the developer must supply initial calculations with loads, structural estimates, and identification of load paths and critical components. The developer must also supply sufficient and appropriate supporting information—such as the design basis, safety margins, and design standards—that will be used in future design.",
    "high_text": "All WEC subsystems and their components have safety margins for extreme load and motion conditions, have fatigue lives expected to exceed system life, and are designed and approved for the environment they operate in. Loads and conditions are well characterized.",
    "medium_text": "Same as high except the loads and conditions are not as well characterized or some uncertainty exists within the structural reliability modeling. Components and subsystems are designed with easy access for replacement.",
    "low_text": "Some components are expected to fail in extreme load conditions or during the life of the system, or loads and motions are not well known.",
    "invalidated_by": 0
})

QUESTIONS.push({
    ...INVESTMENT2_DEFAULT,
    "id": 46,
    "text": "What are the known failure modes and frequency of failure for WEC subsystems and their components? What is the level of confidence for failure modes and frequency? In terms of OpEx uncertainty, how well have the failure modes and frequency of failures been characterized and costed? What are the consequences of failure?",
    "guidance": "The WEC should use proven or low-risk technologies to maximize availability. Are the identified failure modes and frequency of failures adequately assessed, and is there a good degree of certainty that major failure modes have not been missed? This question should assess the uncertainty and the potential impact those of uncertainties on OpEx.",
    "high_text": "Most or all subsystems and components are mature technologies that have been used in similar environments. Mean time between failures for each component is more than planned WEC life, or component is low-cost and easy to remove and replace. Components that may fail do not endanger WEC for loss or further damage because of failing safe or redundancy. ",
    "medium_text": "Some new technology components or components with no history of use in this environment are used, so failure rate data are not reliable for this application. Components with expected failures are expensive. Removal and replacement of parts can be difficult on location, or specialized boats or equipment are required. Failure results in full loss of function or increased danger to the WEC.",
    "low_text": "Several components are new designs with no operational history in this environment, or there is high difficulty in replacing or repairing failed parts. Some parts that may fail result in significant increase in the probability of loss or further damage to the WEC.",
    "invalidated_by": 26
})


QUESTIONS.push({
    ...INVESTMENT2_DEFAULT,
    "id": 47,
    "text": "What are the limiting sea states that allow maintenance access? How is relative motion between the WEC and work platform minimized? Or motion between the WEC and power take-off mooring?",
    "guidance": "Offshore maintenance of WECs can be expensive. If it can only be done in a narrow weather window—even if the task is quick or requires minimal support—cost can quickly rise if assets are on hold waiting for the weather. The larger the weather window, the more devices that can be maintained in a given period, thus reducing costs and device downtime. For this question, how well have the maintenance sea states been defined, and how likely are the maintenance activities to be conducted in the specified sea states?",
    "high_text": "The device can be serviced in Sea State 4 (Beaufort Scale) and greater.",
    "medium_text": "The device can be serviced in Sea State 3 or less.",
    "low_text": "The device can be serviced in Sea State 2 or less.",
    "invalidated_by": 28
})

QUESTIONS.push({
    ...INVESTMENT2_DEFAULT,
    'subsection': 11,
    "id": 48,
    "text": "Can the WEC device absorb energy effectively over a range of wave resources that are expected for early development? Is the WEC insensitive to wave direction? Was the performance modeling performed with validated tools that give a high confidence in the projected performance?",
    "guidance": "The WEC should not be limited to operating in a narrow resource found at limited sites but should be able to produce its nominal peak power for many different resources and, thus, at many different sites. Small variations in resource do not result in large variation in performance. For this question, consider the uncertainty of the project performance. How well was the modeling performed? Was an accurate model of the device and control system used?",
    "high_text": "The WEC can produce 80% plus of nominal peak power with a wave resource from 20 to 40 kW/m and from any direction.",
    "medium_text": "Nominal peak power is only possible over a narrower range that spans less than 10 kW/m or within 35 degrees of design.",
    "low_text": "Nominal peak power is only possible in a limited range of a high-wave resource, such as in excess of 30 kW/m or within 15 degrees of design.",
    "invalidated_by": 29
})

QUESTIONS.push({
    ...INVESTMENT2_DEFAULT,
    "subsection": 12,
    "id": 49,
    "text": "Are the WEC subsystems designed for the expected extreme loads and motion, for the lifetime operating loads, and for the operational environment? Are all components mature technology with a history of use in the marine environment?",
    "guidance": "How certain are the expected maintenance and repair costs? For this question, keeping in mind that this is a low-technology-readiness-level system, look at the design methods, environmental conditions, and design assumptions used to design and evaluate the WEC. Are they sufficient, or have they missed important aspects that will impact the uncertainty of the OpEx of the design?. If any components (e.g., umbilicals, structural components) are not well designed, this is the opportunity to catch this. <br/><br/>At this low-technology-readiness-level assessment, a detailed structural design should not be expected. Instead, to achieve a high score, the developer must supply initial calculations with loads, structural estimates, and identification of load paths and critical components. The developer must also supply sufficient and appropriate supporting information—such as the design basis, safety margins, and design standards—that will be used in future design.",
    "high_text": "All WEC subsystems and their components have safety margins for extreme load and motion conditions, have fatigue lives expected to exceed system life, and are designed and approved for the environment they operate in. Loads and conditions are well characterized.",
    "medium_text": "Same as high except the loads and conditions are not as well characterized or some uncertainty exists within the structural reliability modeling. Components and subsystems are designed with easy access for replacement.",
    "low_text": "Some components are expected to fail in extreme load conditions or during the life of the system, or loads and motions are not well known.",
    "invalidated_by": 0
})

QUESTIONS.push({
    ...INVESTMENT2_DEFAULT,
    'subsection': 12,
    "id": 50,
    "text": "What are the known failure modes and frequency of failure for WEC subsystems and their components? What is the level of confidence for failure modes and frequency? What are the consequences of failure?",
    "guidance": "The WEC should use proven or low-risk technologies to maximize availability. Are the identified failure modes and frequency of failure adequately assessed, and is there a good degree of certainty that major failure modes have not been missed? This question should assess the uncertainty and the potential impact of those uncertainties on availability.",
    "high_text": "Most or all subsystems and components are mature technologies that have been used in similar environments. Mean time between failures for each component is more than planned WEC life, or component is low-cost and easy to remove and replace. Components that may fail do not endanger WEC for loss or further damage because of failing safe or redundancy.",
    "medium_text": "Some new technology components or components with no history of use in this environment are used, so failure rate data are not reliable for this application. Components with expected failures are expensive. Removal and replacement of parts can be difficult on location, or specialized boats or equipment are required. Failure results in full loss of function or increased danger to the WEC.",
    "low_text": "Several components are new designs with no operational history in this environment, or there is high difficulty in replacing or repairing failed parts. Some parts that may fail result in significant increase in the probability of loss or further damage to the WEC.",
    "invalidated_by": 26
})

QUESTIONS.push({
    ...INVESTMENT2_DEFAULT,
    "subsection": 12,
    "id": 51,
    "text": "What are the limiting sea states that allow maintenance access? How is relative motion between the WEC and work platform minimized? Or motion between the WEC and power take-off mooring?",
    "guidance": "Offshore maintenance of WECs can be expensive. If it can only be done in a narrow weather window—even if the task is quick or requires minimal support—cost can quickly rise if assets are on hold waiting for the weather. The larger the weather window, the more devices that can be maintained in a given period, thus reducing costs and device downtime. For this question, how well have the maintenance sea states been defined, and how likely are the maintenance activities to be conducted in the specified sea states?",
    "high_text": "The device can be serviced in Sea State 4 (Beaufort Scale) and greater.",
    "medium_text": "The device can be serviced in Sea State 3 or less.",
    "low_text": "The device can be serviced in Sea State 2 or less.",
    "invalidated_by": 28
})

const GRID_DEFAULT = ({
    "group": 2, 
    "section": 5,
    "subsection": null,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...GRID_DEFAULT,
    "id": 52,
    "text": "What is the target capacity factor?",
    "guidance": "A farm with a high capacity factor, defined as the ratio of the farm output over a year to its potential output if it were operating continuously at full nameplate capacity, characterizes low long-term variability—thus contributing to forecastability. Long-term forecastability assists the grid operator in planning future energy capacities and reserves.",
    "high_text": "Target capacity factor is greater than 40%.",
    "medium_text": "Target capacity factor is between 20% and 40%.",
    "low_text": "Target capacity factor is less than 20%.",
})

const BENEFIT_DEFAULT = ({
    "group": 3, 
    "section": 6,
    "subsection": null,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...BENEFIT_DEFAULT,
    "id": 53,
    "text": "How many operating jobs (life of the project) will the WEC contribute to the local community where it is deployed, in full-time-equivalent jobs per gigawatt of installed capacity?",
    "guidance": "For a large WEC farm of more than 50 MW, how many jobs will be created in local communities for operation, maintenance, logistics, etc.? Ideally, there will be a balance between reliability, durability, maintainability, and job creation to minimize OpEx.",
    "high_text": "The farm will generate more than 50 FTE/GW lasting the lifetime of the farm in the local community.",
    "medium_text": "The farm will generate 20–50 FTE/GW lasting the lifetime of the farm in the local community.",
    "low_text": "The farm will generate less than 20 FTE/GW lasting the lifetime of the farm in the local community."
})

QUESTIONS.push({
    ...BENEFIT_DEFAULT,
    "id": 54,
    "text": "How many construction/manufacturing jobs will the WEC contribute to the local community where the farm is located during farm construction, in full-time-equivalent job years per gigawatt of installed capacity? This includes local manufacturing and assembly if that is normally done locally.",
    "guidance": "For a large WEC farm of more than 50 MW, how many jobs will be created in local communities during the construction of the farm?",
    "high_text": "The farm will generate more than 100 FTE years/GW during construction in the local community.",
    "medium_text": "The farm will generate 40–100 FTE years/GW during construction in the local community.",
    "low_text": "The farm will generate less than 40 FTE years/GW during construction in the local community."
})

QUESTIONS.push({
    ...BENEFIT_DEFAULT,
    "id": 55,
    "text": "How many jobs will the WEC contribute to the local community where it is manufactured, in units of full-time-equivalent job years per gigawatt of manufactured WECs? This includes local manufacturing, logistics, engineering, design, management, and administrative jobs. This does not include jobs local to the installation site if some manufacturing is done locally to the WEC installation.",
    "guidance": "At the location(s) where the WECs are manufactured, how many jobs will the manufacturing facilities directly contribute to the local community?",
    "high_text": "The farm will generate more than 50 FTE/GW lasting the lifetime of the farm in the local community.",
    "medium_text": "The farm will generate 20–50 FTE/GW lasting the lifetime of the farm in the local community.",
    "low_text": "The farm will generate less than 20 FTE/GW lasting the lifetime of the farm in the local community."
})

const BENEFIT2_DEFAULT = ({
    "group": 3, 
    "section": 7,
    "subsection": null,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...BENEFIT2_DEFAULT,
    "id": 56,
    "text": "How long will it take for the WEC device to repay its energy debt? Include energy for material production, manufacturing of components, procurement, construction, and decommissioning.",
    "guidance": "The WEC should have minimal impact on the environment. Its cumulative energy production should quickly surpass the net energy it took to construct, transport, assemble, and deploy  the WEC and its balance of station.",
    "high_text": "Less than 2 years in a 20-kW/m resource.",
    "medium_text": "Less than 3 years in a 25-kW/m resource.",
    "low_text": "More than 4 years in a 25-kW/m resource."
})

QUESTIONS.push({
    ...BENEFIT2_DEFAULT,
    "id": 57,
    "text": "Are the WEC and its components recyclable?",
    "guidance": "The WEC should be recyclable to minimize its environmental impact.",
    "high_text": "Nearly all (less than 10% not recyclable)",
    "medium_text": "Most of it (more than 70% by weight)",
    "low_text": "Less than 70% by weight"
})

QUESTIONS.push({
    ...BENEFIT2_DEFAULT,
    "id": 58,
    "text": "What is the expected lifetime of the WEC device?",
    "guidance": "The WEC should be designed to survive as long as possible to reduce the energy and materials needed to manufacture replacement systems.",
    "high_text": "25 years",
    "medium_text": "20 years",
    "low_text": "15 years"
})


const PERMIT_DEFAULT = ({
    "group": 4, 
    "section": 8,
    "subsection": null,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...PERMIT_DEFAULT,
    "id": 59,
    "text": "Do any characteristics of the system restrict its application in environmentally sensitive locations?",
    "guidance": "The WEC should be as easy to permit as possible; thus, the design should minimize environmental impacts and risks.",
    "high_text": "The system is benign and can be deployed without restriction for environmental impact.",
    "medium_text": "The system is not completely benign, but impacts are minor and of only one type, such as sediment impact or noise, that is acceptable in most locations.",
    "low_text": "The system will have an impact in several ways, or the impact will likely require mitigation or eliminate many sites."
})

QUESTIONS.push({
    ...PERMIT_DEFAULT,
    "section": 9,
    "id": 60,
    "text": "Do any characteristics of the system produce an impact on wildlife that would restrict its application where threatened or protected species (e.g., whales and other marine mammals) exist?",
    "guidance": "The WEC should be as easy to permit as possible; thus, the design should minimize ecological impacts and risks.",
    "high_text": " The system presents no threat to birds, fish, or marine mammals.",
    "medium_text": "The system presents minor threat to marine mammals, such as potential injury to whales from impacting cables.",
    "low_text": "The system presents more than a minor threat to marine mammals, fish, or birds or interferes with migration because of high noise levels."
})

QUESTIONS.push({
    ...PERMIT_DEFAULT,
    "section": 10,
    "id": 61,
    "text": "Can the technology form a farm that can coexist with other potential users of the area  (e.g., fishing fleets, surfers, shipping boats, sailing boats)?",
    "guidance": "When formed in an array, the WEC farm should not restrict area use other than local to the WEC. The WEC should not be a hazard to navigation or present a danger to other area users.",
    "high_text": "The farm can coexist with the majority of potential users of any area (e.g., WECs are below the surface and will not affect surface use, or surface WECs will not require a large area exclusion zone).",
    "medium_text": "The farm will restrict multiple users but not extensively (e.g., the WEC devices will be separated to allow many types of users to go through the installation), or it will affect some users significantly but not affect other users.",
    "low_text": "The farm will restrict multiple users, and the area of restriction will include the entire site."
})

QUESTIONS.push({
    ...PERMIT_DEFAULT,
    "section": 10,
    "id": 62,
    "text": "Given the desired farm rated power and the expected footprint, what area will the farm occupy per rated farm power? <br/><br/>Use the layout of a typical array and the dimensions from the outside of the outermost anchor to the outermost  anchor on the other side in both directions to calculate the footprint area. Use the rated power of the WEC devices times the number of devices for the denominator.",
    "guidance": "The area used by the WEC array should be minimal for the power producing potential to minimize conflict.",
    "high_text": "1 x 10<sup>4</sup> m<sup>2</sup>/MW",
    "medium_text": "9 x 10<sup>4</sup> m<sup>2</sup>/MW",
    "low_text": "36 x 10<sup>4</sup> m<sup>2</sup>/MW"
})

const SAFETY_DEFAULT = ({
    "group": 5, 
    "section": 11,
    "subsection": null,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "id": 63,
    "text": "Has a safety philosophy been incorporated into the design process?<br/><br/>This may include adopting best practices and appropriate formal standards at the design stage, appointing a responsible person to take charge of safety, reviewing the design for safety early and designing out risks early, designing in mitigation for risks that cannot be eliminated, ensuring designers are suitably qualified and trained, and keeping appropriate records.",
    "guidance": "Safety needs to be considered at the earliest stages of the WEC design to minimize risk to people, property, and the environment. Changes at higher technology readiness levels can be costly.",
    "high_text": "Safety philosophy is incorporated and adequate.",
    "medium_text": "Some safety aspects have been considered and are adequate, but some areas need improvement.",
    "low_text": "Safety philosophy is not incorporated or is generally inadequate."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "id": 64,
    "text": "Is there a threat to human health and safety during any life cycle stage? <br/><br/>Consider all life stages, including design, manufacturing, assembly, lifting, transport, installation, operation, maintenance, removal, and decommissioning.",
    "guidance": "The WEC design must minimize risk of human injury and death.",
    "high_text": "All activities are well understood, and adequate safety systems and procedures have been documented. No access to dangerous parts is available to third parties. The risk to human health and safety is low.",
    "medium_text": "All activities include documented safe operating procedures, but one or more activities is novel and not yet well understood or access to dangerous parts by third parties is discouraged but can’t be prevented. There is a medium threat to human health and safety.",
    "low_text": "Human health and safety are not considered, or operation and maintenance procedures do not have adequate safety guidance. The risk is high."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "id": 65,
    "text": "What are the limiting sea states that allow maintenance access? How is relative motion between the WEC and work platform minimized? Or motion between the WEC and power take-off mooring?",
    "guidance": "The WEC must be safely accessible in all of the specified maintenance sea states.",
    "high_text": "The device can be serviced in Sea State 4 (Beaufort Scale) and greater. Maintenance methodologies are such that adequate safety is provided when using commonly available vessels and equipment within their standard weather windows.",
    "medium_text": "The device can be serviced in Sea State 3 or less. Maintenance methodologies are such that adequate safety is only provided when using commonly available vessels and equipment with more stringent weather window criteria.",
    "low_text": "The device can be serviced in Sea State 2 or less. Maintenance proposed methodologies are fundamentally unsafe or are not developed and specified.",
    "invalidated_by": 28
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "id": 66,
    "text": "Does the design require personnel to enter an enclosed space at sea?",
    "guidance": "Enclosed spaces at sea can be risky if they are not property designed for safe ingress and egress. If a space is tight, is not well ventilated, or has hazardous machinery, it can present a risk for injury or death. If maintenance activities require a person to enter, either in whole or part, the space should be safe for that person and allow a quick exit if needed.",
    "high_text": "No, or the enclosed space can safely support short-term habitation and has a safe means of egress.",
    "medium_text": "Yes, for a few operations (less than one per 5 MW/year).",
    "low_text": "Yes, several times per megawatt per year."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "id": 67,
    "text": "Does the design require personnel (e.g., divers) to work in or under the sea?",
    "guidance": "Installation and maintenance activities should not require a person to enter the water. These should be able to be accomplished with remotely operated systems. Any time a person enters the water, there is a risk of injury or death.",
    "high_text": "No.",
    "medium_text": "Yes, for a few exceptional operations (i.e., not every installation, not routine maintenance, but maybe for an infrequent type of maintenance intervention).",
    "low_text": "Yes, installation or several potential maintenance operations require divers."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "id": 68,
    "text": "Is any lifting by crane done at sea?",
    "guidance": "Lifting operations can be hazardous at sea if no provision is made for load control or if heavy items need to be transferred.",
    "high_text": "Requirements for lifting have been designed out, or lifting only requires a light load, and load control has been developed into the maintenance plan.",
    "medium_text": "Yes, for a few exceptional operations (i.e., not every installation, not routine maintenance, but maybe for an infrequent type of maintenance intervention).",
    "low_text": "Yes, installation or several expected maintenance operations require crane lifts at sea."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 13,
    "id": 69,
    "text": "How susceptible are the WEC device and station-keeping system to increasingly energetic conditions? How do they react (in terms of motions and loads) to highly energetic environments (i.e., large return period environments)?",
    "guidance": "WECs must be designed to survive expected storm conditions. Does the WEC design consider relevant storm return periods and have a well-thought-out and realistic survival strategy? Only consider the device survivability and safety in this question. Costs are considered elsewhere.",
    "high_text": "WECs with surface expression that are subject to the large energy in the forcing environment are designed to decouple (i.e., load shed, submerge), or the WEC has no surface expression.",
    "medium_text": "WECs possess only minimal surface expression, they possess a limited-scope decoupling mechanism, or the shape limits extreme loads and allows sufficient structural reinforcement in the design.",
    "low_text": "WECs with surface expression are subject to the large energy in the forcing environment because they have no special coping mechanisms."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 13,
    "id": 70,
    "text": "What is the design probability of WEC loss?",
    "guidance": "How well has the device been designed to survive and not sink? Does it include strategies such as separate internal chambers, flooding control, and reserve buoyancy? Ideally, for every thousand WECs deployed, only one would be lost every 10 years in design conditions.",
    "high_text": "High safety class <10<sup>4</sup> losses/annum.",
    "medium_text": "Between high and low.",
    "low_text": "Low safety class >10<sup>3</sup> losses/annum."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 14,
    "id": 71,
    "text": "Are WEC subsystems at risk of damage by grid failure, grid loss, or grid interruption?",
    "guidance": "The grid and connections to the grid can be subject to rapid events, such a disconnections. In the event of a sudden disconnection or other unexpected grid behavior, the WEC should not react in an adverse way that can cause damage to its components.",
    "high_text": "No components are expected to experience increased failure rates due to loss of grid.",
    "medium_text": "There is some increase of risk of minor failure as a result of loss of grid.",
    "low_text": "There is significant increased risk of even minor failures due to loss of grid."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 14,
    "id": 72,
    "text": "Does loss of grid result in a safe state for the WEC device? Can it continue to protect itself and be in a state that is safe for extreme conditions?",
    "guidance": "When a loss-of-grid event happens, the WEC must be to able quickly, automatically, and safely transition to a safe state.",
    "high_text": "Yes, the WEC is inherently safe or transitions to a safe state automatically with loss of grid and can stay in that safe state indefinitely.",
    "medium_text": "Yes, but it can only maintain that safe state for several days.",
    "low_text": "Loss of grid reduces the survivability of the WEC."
})

QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 14,
    "id": 73,
    "text": "Can the WEC operate without the grid, even in a reduced capacity, and power other WECs?",
    "guidance": "The WEC should be able to self-power and have sufficient energy reserve to maintain critical systems so it can maintain a safe state and be ready for operation when grid connection resumes—even if the interruption is one or more months.",
    "high_text": "Yes (e.g., one WEC can power systems in other WECs).",
    "medium_text": "Yes, but it has limited capabilities in some critical system.",
    "low_text": "No."
})


QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 15,
    "id": 74,
    "text": "Can the WEC be easily detected by other users of the area?",
    "guidance": "WEC devices that represent a hazard to navigation (i.e., are located at the surface or are submerged but can be hit by recreational boaters) must be readily detectible by area users. WECs must be able to support lighting. If their above-water structure is not sufficient for visual and radar identification in storms, other identification mechanisms such as radar reflectors, RACONs, AIS, and marker buoys. must be used for signaling to other users of the area.",
    "high_text": "The WEC is sufficiently submerged to avoid recreational boaters, or it has the ability to attach light and radar reflectors when its surface structure is insufficient for radar identification in storm conditions.",
    "medium_text": "The WEC represents a hazard to navigation but cannot directly integrate navigation aids and, thus, must use physically separate systems for identification, such as marker buoys.",
    "low_text": "The WEC represents a hazard to navigation and cannot use any means of identification in close proximity. This could include WECs with large watch circles that are submerged less that 3 m below the sea surface."
})


QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 15,
    "id": 75,
    "text": "In the event of a collision, is the WEC able to mitigate damage?",
    "guidance": "If a vessel collides with the WEC, the impacts of the collision should be minimized.",
    "high_text": "The WEC outer structure makes a direct impact difficult (e.g., rounded surfaces make a glancing blow more likely), a compliant station-keeping system design allows the WEC to move during a collision, or the WEC is submerged so it isn't a hazard to navigation.",
    "medium_text": "There is a low probability of direct collision, but it is possible, or a stiff but compliant station-keeping design is used (e.g., a taut mooring).",
    "low_text": "Direct collisions are likely (e.g., large, flat surfaces) or a rigid station-keeping design results in a direct transference of impact forces to the WEC."
})


QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 16,
    "id": 76,
    "text": "For all expected orientations and configurations other than during operation and survival, is the WEC as safe and survivable?",
    "guidance": "If the WEC's configuration or orientation must be changed for activities such as tow-out, installation, and maintenance, has the WEC been designed so that other configurations and changes between configurations are as safe as the operating configuration?",
    "high_text": "Same orientation or equally safe survival capability.",
    "medium_text": "Different orientation but safely and easily reoriented.",
    "low_text": "Different orientation that requires complex operation to be reoriented."
})


QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 17,
    "id": 77,
    "text": "Are the WEC and its subsystems designed for the expected extreme loads, the operating loads for the lifetime of the system, and the operational environment?",
    "guidance": "This question aims to assess the adequacy of the design methods and assumptions applied in developing the WEC. Keep in mind that this is a low-technology-readiness-level evaluation.<br/><br/>At this low-technology-readiness-level assessment, a detailed structural design should not be expected. Instead, to achieve a high score, the developer must supply initial calculations with loads, structural estimates, and identification of load paths and critical components. The developer must also supply sufficient and appropriate supporting information—such as the design basis, safety margins, and design standards—that will be used in future design.",
    "high_text": "All WEC subsystems and their components have safety margins for extreme load conditions and fatigue lives expected to exceed system life and are designed and approved for the environment they are operate in. Loads and conditions are well characterized.",
    "medium_text": "Same as high except the loads and conditions are not as well characterized.",
    "low_text": "Some components are expected to fail in extreme load conditions or during the life of the system, and loads are not well known.",
    "invalidated_by": 0
})


QUESTIONS.push({
    ...SAFETY_DEFAULT,
    "section": 12,
    "subsection": 18,
    "id": 78,
    "text": "Does the device need multiple configurations to limit stress in high-energy situations, to increase energy capture, or for another reason? If so, how complex (risky or slow) is the transformation?<br/><br/> A change in depth is a simple change. Even so, it should be evaluated for reliable or fail-safe operation. A change in orientation (e.g., vertical to horizontal) of the WEC or a major part of the WEC is complex and requires careful evaluation of risk.",
    "guidance": "If a device uses configuration change—such as a change in geometry, depth, or orientation or another physical change to increase energy production, reduce load, or minimize storm load—those changes need to be done reliably over the lifetime of the WEC. Complex configuration changes that require many actuators should be avoided because of decreased reliability. Also, in the event of a failure of an actuator, the WEC should fail to a safe configuration.",
    "high_text": "No change in WEC configuration is required, or it requires one change that is safe, reliable, easily maintainable, and a failure in the transition system results in going to a safe state.",
    "medium_text": "Two or three configurations are needed with high reliability and low maintenance.",
    "low_text": "Two or more configuration changes that have many single point-failures are needed, which may put the device survival at risk."
})


const GLOBALLY_DEFAULT = ({
    "group": 6, 
    "section": 13,
    "subsection": null,
    "subsubsection": null,
    "omitted": false,
    "text": "",
    "guidance": "",
    "low_text":"",
    "medium_text":"",
    "high_text":"",
    "invalidated": false,
    "invalidated_by": null,
    "weight": 1,
    "score": -1,
    "score_confidence": null,
    "justification": "",
    "comment": null      
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 79,
    "text": "What is the water depth required to deploy the WEC farm?",
    "guidance": "The WEC technology should be deployable in a range of water depths to maximize utility and minimize site limitations. Technologies that only allow onshore installations should be avoided because they have very limited opportunities.",
    "high_text": "Water depth greater than 5 m is required. The WEC farm can be installed near shore and deep offshore.",
    "medium_text": "The device requires very specific water depth: less than 20 m, more than 100 m, or a narrow range of depth, such as 20–40 m.",
    "low_text": "Onshore only."
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 80,
    "text": "What geophysical conditions are required to deploy this concept?",
    "guidance": "Bottom types can vary between wave sites and even within a wave site. The bottom type required to support the WEC should be as flexible as possible and should consider different bottom types, bottom slopes, etc.",
    "high_text": "Any bottom type acceptable.",
    "medium_text": "Limited options acceptable for bottom material",
    "low_text": "Only one bottom type, such as solid rock, acceptable."
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 81,
    "text": "What is the minimum feasible wave resource for attractive levelized cost of energy?",
    "guidance": "The WEC should be economically viable in a range of wave resources so it can be used at the maximum number of sites.",
    "high_text": "20 kW/m",
    "medium_text": "20–30 kW/m",
    "low_text": "Larger than 40 kW/m"
})


QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 82,
    "text": "What is the sensitivity tidal range?",
    "guidance": "Tides are common at many wave sites, so the WEC should be minimally limited by tidal ranges so it can be used at the maximum number of sites.",
    "high_text": "System is insensitive to tidal range.",
    "medium_text": "Tidal range of 3.5 m or more has significant impact on energy yield, costs, or survivability.",
    "low_text": "Tidal range of 2 m or more has significant impact on energy yield, costs, or survivability."
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 83,
    "text": "What is the sensitivity to current?",
    "guidance": "Currents are common at many wave sites, so the WEC should be minimally limited by currents so it can be used at the maximum number of sites.",
    "high_text": "The system is unaffected by current.",
    "medium_text": "Currents less than 0.5 m/s have no adverse impact.",
    "low_text": "Currents less than 0.2 m/s have no adverse impact."
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 84,
    "text": "Do any characteristics of the system restrict its application in environmentally sensitive locations (e.g., large shifts in sediments, noise emissions, other emissions)?",
    "guidance": "The WEC should minimally impact the environment so it can be used at the maximum number of sites.",
    "high_text": "The system is benign and can be deployed without restriction.",
    "medium_text": "The system has some environmental issues that will impact specific areas; hence, the technology will have some restrictions that limit deployments in some sites, but overall, the technology is compatible with the majority of sites being considered for WEC deployment.",
    "low_text": "The system has some broad environmental concerns that will limit deployment at many sites.",
    "invalidated_by": 59
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 85,
    "text": "Do any characteristics of the system restrict its application in ecologically sensitive locations (e.g., endangered and threatened species, migratory routes)?",
    "guidance": "The WEC should minimally impact the ecology so it can be used at the maximum number of sites.",
    "high_text": "The system is benign and can be deployed without restriction.",
    "medium_text": "The system has some ecological issues that will impact specific areas; hence, the technology will have some restrictions that limit deployments in some sites, but overall, the technology is compatible with the majority of sites being considered for WEC deployment.",
    "low_text": "The system has some broad ecological concerns that will limit deployment at many sites.",
    "invalidated_by": 60
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 86,
    "text": "Can manufacturing, construction, assembly, and installation capabilities and infrastructure be found or easily developed at many coastal locations, as needed, to develop a farm?",
    "guidance": "Capabilities need for farm development—such as manufacturing, construction, assembly, and installation—should be readily available or straightforward to establish at coastal locations to maximize deployment locations.",
    "high_text": "The manufacturing, construction, assembly, and installation capabilities, as needed, exist or can be easily developed (with minimal time and cost) in most coastal locations globally.",
    "medium_text": "Requires facilities or capabilities that are between common and seldom available, or cost or time to develop is significant enough that it might impact project feasibility.",
    "low_text": "Requires facilities or capabilities that are only available in a few locations or would take considerable time or cost to develop (likely not cost-effective to develop in many locations).",
    "invalidated_by": 16
})

QUESTIONS.push({
    ...GLOBALLY_DEFAULT,
    "id": 87,
    "text": "What is the theoretical global wave energy capacity that is suitable for capture by the WEC farm (i.e., the estimated global size of the resource that can be exploited by the WEC farm taking into account physical site conditions, manufacture and installation logistics, and port infrastructure)?",
    "guidance": "The theoretical maximum wave energy extraction that can be achieved by the WEC should be sufficient to justify mass-manufacturing levels needed to achieve scales of economy. If the WEC is only economic at a small number of sites, its viability is limited, and it will have very limited market potential.",
    "high_text": "Greater than 500 GW",
    "medium_text": "Between 100 GW and 500 GW",
    "low_text": "Less than 100 GW"
})

const questionsToLink = QUESTIONS
    .filter(question => question.invalidated_by !== null)
    .map(question => { return {questionId: question.id, parentId: question.invalidated_by} })

questionsToLink.forEach(idPair => {
    let question = QUESTIONS.find(question => question.id === idPair.questionId)
    question.text += `<br/><br/>This question applies to multiple topics and contributes to multiple scores. To add or change the score, see the <span class='QuestionLink' data-question_id='${idPair.parentId}'>source question</span>.`;
})

export default QUESTIONS