import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import '../styles/ContactPage.css'

class Contact extends React.Component {
  componentDidMount = () => {
    document.title = 'Contact Us | Technology Performance Level Assessment | NREL'
  }

  render = () => {
    return (
      <div className="Page">


        <section>
          <Container className="Body">
              <Row>
                <Col md={12}>
                  <h1>Contact Us</h1>
                  <div>
                    Contact us with your questions about the Technology Performance Level Assessment tool for wave energy converters.
                    <section className="Contacts">
                      <div className="Contact">
                        <div className="Contact__Name">Jochem Weber</div>
                        <div className="Contact__Address">
                          <a href="mailto:Jochem.Weber@nrel.gov">Jochem.Weber@nrel.gov</a> |  303-384-7095
                        </div>
                      </div>
                      <div className="Contact">
                        <div className="Contact__Name">Thomas Mathai</div>
                        <div className="Contact__Address">
                          <a href="mailto:Thomas.Mathai@nrel.gov">Thomas.Mathai@nrel.gov</a> | 303-384-7445
                        </div>
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
          </Container>
        </section>
      </div>
    )
  }
}

export default Contact