import React from 'react'
import {Link} from 'react-router-dom'
import { Jumbotron, Container, Row, Col, Button } from 'reactstrap'
import '../styles/MainPage.css'

export default function () {
    return (
        <div className="MainPage">
            <Container fluid className="Hero">
                <Jumbotron>
                    <div className="container">
                        <Row>
                            <Col md={7}>
                                The Technology Performance Level Assessment tool evaluates the techno-economic performance potential of early-stage wave energy 
                                converter concepts.
                            </Col>
                            <Col md={4} style={{paddingLeft: '40px'}}>
                                <Link to="/assessment"><Button>Start Assessment</Button></Link>
                            </Col>
                        </Row>
                    </div>
                </Jumbotron>
            </Container>

            <section>

            <Container className="Body">
                <Row>

                        <Col md={8}>                    
                            <h3>The technology performance level assessment can be used by:</h3>
                            <ul>
                                <li>
                                    Technology developer to get design feedback, identify areas of improvement, and find fatal flaws early
                                </li>
                                <li>
                                    Investors and project developers to conduct due diligence
                                </li>
                                <li>
                                    Reviewers to assess wave energy converter proposals and make funding decisions
                                </li>
                                <li>
                                    Reviewers to assess wave energy converter technologies in competitions
                                </li>
                                <li>
                                    Researchers to landscape the domain to formulate R&D strategy.
                                </li>
                            </ul>
        <p>The Technology Performance Level Assessment methodology was jointly developed by NREL and Sandia National Laboratories.</p>
                        </Col>
                </Row>
            </Container>
            </section>
        </div>
    )
  }
