import React, {Component} from 'react'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown/with-html'
import { Row, Col, Label, Input, Collapse }  from 'reactstrap'
import {setQuestionScore, setScores, omitQuestion, restoreQuestion, setQuestionUncertainty, saveComment} from '../actions'

import '../styles/Question.css'

class Question extends Component {

    state = {
        score: this.props.score,
        uncertainty: this.props.uncertainty,
        comment: this.props.comment,
        guidanceOpen: this.props.guidanceOpen,
        scoringGuidanceOpen: this.props.scoringGuidanceOpen,
        omitted: this.props.omitted
    }

    toggleGuidance = () => {
        this.setState({guidanceOpen: !this.state.guidanceOpen})
    }

    toggleScoringGuidance = () => {
        this.setState({scoringGuidanceOpen: !this.state.scoringGuidanceOpen})
    }
    
    toggleOmitted = () => {
        if (this.state.omitted) {
            this.props.restoreQuestion(this.props.question.id)
        } else {
            this.props.omitQuestion(this.props.question.id)
        }
        this.setState({omitted: !this.state.omitted})
        this.props.setScores()
    }

    onScoreChange = async (e) => {
        this.props.setQuestionScore(this.props.question.id, e.target.value)
        .then(this.props.setScores)
    }

    onUncertaintyChange = async (e) => {
        this.props.setQuestionUncertainty(this.props.question.id, e.target.value)   
    }

    handleJustificationChange = (e) => {
        // dispatches the change to the redux store 
        this.props.saveComment(this.props.question.id, e.target.value)
    }

    render = () => {
        
        const score = this.props.score ? this.props.score : 0
        const uncertainty = this.props.uncertainty ? this.props.uncertainty : 0
        const omittedLinkText = this.state.omitted ? 'Restore Omitted Question' : 'Omit Question'
        const omittedClassName = this.state.omitted ? 'Question--omitted' : ''
        const invalidatedClassName = this.props.question.invalidated_by !== null ? 'Question--invalidated' : ''
        const colorClassName = "TRL1-" + this.props.activeGroupId
        const disabled = this.state.omitted || this.props.question.invalidated_by !== null
        const toggleIcon = (this.props.question.omitted === false) ? <svg aria-hidden="true" className="redIcon" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg> : <svg aria-hidden="true" className="greenIcon" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>
        const toggleColorClass = (this.props.question.omitted === false) ? 'redText' : 'greenText'
        const guidanceIcon = (this.state.guidanceOpen) ? <svg aria-hidden="true" focusable="false" className="minusIcon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg> :  <svg aria-hidden="true" className="plusIcon" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
        const scoringGuidanceIcon = (this.state.scoringGuidanceOpen) ? <svg aria-hidden="true" focusable="false" className="minusIcon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>  :  <svg aria-hidden="true" className="plusIcon" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
        
        return (
            <div id={`Question-${this.props.question.id}`} className={`Question ${omittedClassName} ${invalidatedClassName} ${colorClassName}`}>
                <Row>
                    <Col md={5}>
                        <div className="Question__QuestionText">
                            <ReactMarkdown source={this.props.question.text} escapeHtml={false}/>
                        </div>
                        <span className="Question__GuidanceButton ToggleButton" onClick={this.toggleGuidance}>
                            Question Guidance {guidanceIcon}
                        </span>
                        <div className="Question__Guidance">
                            <div className="Question__Guidance__Text">
                                <Collapse isOpen={this.state.guidanceOpen}>
                                    <ReactMarkdown source={this.props.question.guidance} escapeHtml={false}/>
                                </Collapse>
                            </div>
                        </div>
                        <span className="Question__ScoringGuidanceButton ToggleButton" onClick={this.toggleScoringGuidance}>
                            Scoring Guidance {scoringGuidanceIcon}
                        </span>
                        <div className="Question__ScoringGuidance">
                            <div className="Question__ScoringGuidance__Container">
                                <Collapse isOpen={this.state.scoringGuidanceOpen}>
                                    <div className="Question__ScoringGuidance__Text Question__ScoringGuidance--high">
                                        <strong>High</strong> <ReactMarkdown source={this.props.question.high_text} escapeHtml={false}/>
                                    </div>
                                    <div className="Question__ScoringGuidance__Text Question__ScoringGuidance--medium">
                                        <strong>Medium</strong> <ReactMarkdown source={this.props.question.medium_text} escapeHtml={false}/>
                                    </div>
                                    <div className="Question__ScoringGuidance__Text Question__ScoringGuidance--low">
                                        <strong>Low</strong> <ReactMarkdown source={this.props.question.low_text} escapeHtml={false}/>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        <span className={`Question__OmitButton ToggleButton ${toggleColorClass}`} onClick={this.toggleOmitted}>
                            {toggleIcon}{omittedLinkText}
                        </span>
                    </Col>
                    <Col md={7}>
                        <table>
                            <tbody>
                            <tr>
                                <td className="QuestionInput__Label">
                                    <Label className="Question__Label col-form-label" for={`Question-${this.props.question.id}-Score`} >Score (1-9)</Label>
                                </td>
                                <td className="QuestionInput__Input">
                                    <Input
                                        disabled={disabled}
                                        type="select"
                                        className={'Question__Input' + (disabled ? ' disabled_q' : '')}
                                        id={`Question-${this.props.question.id}-Score`} 
                                        name={`Question-${this.props.question.id}-Score`} 
                                        placeholder={score} 
                                        onChange={this.onScoreChange} 
                                        value={score}
                                    >
                                        <option value="-1">Select Score</option>
                                        <option value="1">🔴 1</option>
                                        <option value="2">🔴 2</option>
                                        <option value="3">🔴 3</option>
                                        <option value="4">🟡 4</option>
                                        <option value="5">🟡 5</option>
                                        <option value="6">🟡 6</option>
                                        <option value="7">🟢 7</option>
                                        <option value="8">🟢 8</option>
                                        <option value="9">🟢 9</option>
                                    </Input>
                                </td>
                            </tr>

                            <tr>
                                <td className="QuestionInput__Label">
                                    <Label className="Question__Label col-form-label" for={`Question-${this.props.question.id}-Uncertainty`}>Scoring Confidence</Label>
                                </td>
                                <td className="QuestionInput__Input">
                                    <Input 
                                        disabled={disabled}
                                        type="select"
                                        className={'Question__Input' + (disabled ? ' disabled_q' : '')}
                                        id={`Question-${this.props.question.id}-Uncertainty`} 
                                        name={`Question-${this.props.question.id}-Uncertainty`} 
                                        onChange={this.onUncertaintyChange}
                                        placeholder={uncertainty}
                                        value={uncertainty}
                                    >
                                        <option value="0">Select Confidence Level</option>
                                        <option value="Low">🔴 Low</option>
                                        <option value="Medium">🟡 Medium</option>
                                        <option value="High">🟢 High</option>
                                    </Input>
                                </td>
                            </tr>

                            <tr>
                                <td className="QuestionInput__Label">
                                    <Label className="Question__Label col-form-label" for={`Question-${this.props.question.id}-Comment`}>Justification</Label>
                                </td>
                                <td className="QuestionInput__Input QuestionInput__Input--comment">
                                    <Input
                                        className="QuestionInput__Input--comment"
                                        disabled={this.state.omitted}
                                        type="textarea" 
                                        name={`Question-${this.props.question.id}-Comment`} 
                                        id={`Question-${this.props.question.id}-Comment`} 
                                        value={this.props.comment}
                                        onChange={this.handleJustificationChange}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>           
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        activeGroupId: state.activeGroupId
    }
}
const mapDispatchToProps = {
    setQuestionScore,
    setScores,
    omitQuestion,
    restoreQuestion,
    saveComment,
    setQuestionUncertainty
};

export default connect(mapStateToProps, mapDispatchToProps)(Question)