import React, {Component} from 'react'
import { connect } from 'react-redux'
import '../styles/ScoreCharts.css'
import { openSubSection, openSection, openSubSubSection } from '../actions'

class ScoreCharts extends Component {
    setActiveTab = (linkid, scrollto) => {
        if (this.props.label === 'SubSubSection') {
            this.props.openSubSubSection(linkid)
        } else if (this.props.label === 'SubSection') {
            this.props.openSubSection(linkid)
        } else if (this.props.label === 'Section') {
            this.props.openSection(linkid)
        }
        document.getElementById(scrollto).scrollIntoView({
            block: 'center'
        })
    }

    render = () => {
        const groupCharts = this.props.charts.map(chart => {
            const height = chart.score ? (chart.score * 7) + (chart.score * 2) : 10
            const heightStyle = {height: height + 'px'}
            const scoreBarClassName = "ScoreChart__bar TRL4-" + this.props.activeGroupId
            const scrollto = `${this.props.label}-${chart.id}`

            return (
                <li key={`scoreChart-${this.props.label}-${chart.id}`}>
                    <div className="ScoreChart__link" onClick={() => this.setActiveTab(chart.id, scrollto)}>
                        <div className="ScoreChart__score">
                            <span>{chart.score}</span>
                        </div>
                        <div style={heightStyle} className={scoreBarClassName}></div>
                        <p className="ScoreChart__title">{chart.title}</p>
                    </div>
                </li>                
            )
        })

        return (
            <div className="ScoreCharts">
                <ul className="ScoreCharts__Charts">
                    {groupCharts}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        activeGroupId: state.activeGroupId
    }
}

export default connect(mapStateToProps, { openSubSection, openSection, openSubSubSection })(ScoreCharts)
