import React from 'react'
import { Container } from 'reactstrap'

class LoginPage extends React.Component {
  componentDidMount = () => {
    document.title = 'Log In | Technology Performance Level Assessment | NREL'
  }

  render = () => {
    return (
      <Container fluid="xl">
        <h1>Login</h1>
      </Container>
    )
  }
}

export default LoginPage