import { createStore, applyMiddleware, compose } from 'redux'
import { save } from "redux-localstorage-simple"
import reduxThunk from 'redux-thunk'
import reducer from './reducer'

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({serialize: true}) : compose

const enhancer = composeEnhancers(
  applyMiddleware(reduxThunk, save({namespace: 'nrel-tpl-tool', debounce: 500}))
)

export default createStore(reducer, enhancer)