import { 
    PAGE_LOADED, 
    SET_SCORES, 
    SET_QUESTION_SCORE, 
    OMIT_QUESTION, 
    RESTORE_QUESTION,
    OPEN_SECTION,
    OPEN_SUBSECTION,
    OPEN_SUBSUBSECTION,
    CLOSE_SECTION,
    CLOSE_SUBSECTION,
    CLOSE_SUBSUBSECTION,
    SAVE_UNCERTAINTY,
    SAVE_COMMENT,
    LOAD_FROM_FILE,
    SET_GROUP
} from "./actions/_action-types"

import QUESTIONS from "./data/questions"
import { GROUPS, SECTIONS, SUBSECTIONS, SUBSUBSECTIONS } from './data/constants'

const INITIAL_STATE = {
    loaded: false,
    questions: QUESTIONS,
    groups: GROUPS,
    sections: SECTIONS,
    subsections: SUBSECTIONS,
    subsubsections: SUBSUBSECTIONS,
    activeGroupId: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PAGE_LOADED:
            return {
                ...state,
                loaded: action.payload.loaded
            }
        case SET_SCORES:
            return {
                ...state,
                sections: action.payload.sections,
                subsections: action.payload.subsections,
                groups: action.payload.groups,
                subsubsections: action.payload.subsubsections
            }
        case SET_QUESTION_SCORE:
            return {
                ...state,
                questions: action.payload.questions
            }
        case SAVE_UNCERTAINTY:
            return {
                ...state,
                questions: action.payload.questions
            }
        case SAVE_COMMENT: 
            return {
                ...state,
                questions: action.payload.questions
            }
        case OMIT_QUESTION:
            return {
                ...state,
                questions: action.payload.questions
            }
        case RESTORE_QUESTION:
            return {
                ...state,
                questions: action.payload.questions
            }
        case OPEN_SECTION :
            return {
                ...state,
                sections: action.payload.sections
            }
        case OPEN_SUBSECTION :
            return {
                ...state,
                sections: action.payload.sections,
                subsections: action.payload.subsections
            }
        case OPEN_SUBSUBSECTION :
            return {
                ...state,
                sections: action.payload.sections,
                subsections: action.payload.subsections,
                subsubsections: action.payload.subsubsections
            }
        case CLOSE_SECTION :
            return {
                ...state,
                sections: action.payload.sections
            }
        case CLOSE_SUBSECTION :
            return {
                ...state,
                subsections: action.payload.subsections
            }
        case CLOSE_SUBSUBSECTION :
            return {
                ...state,
                subsubsections: action.payload.subsubsections
            }
        case SET_GROUP :
            return {
                ...state,
                activeGroupId: action.payload.activeGroupId
            }
        case LOAD_FROM_FILE :
            return action.payload
        
        default:
            return state
    }
}
